import c3 from 'c3';

const COLORS = ['#7D97B2', '#b1c9e1', '#58799c'];

const viewMarkup = `
  <div class="js-diagram-container" aria-hidden="true">
  </div>
  <span class="show-for-sr"><%- chartAlternativeLabel %></span>
  <div class="js-diagram-footer row mth diagram-legend">
    
      <div class="small-12 medium-6 large-8 ml1 columns mbh" aria-hidden="true">
        <span class="legend-heading color-deep-medium-navy">Legend:</span>
        <br />
        <div class="row">
          <% for (var i = 0; i < groups.length; i++) { %>
            <div class="small-12 medium-12 large-<%- 5 + ((i%2) * 2) %> columns float-left">
              <span class="box" style="background-color: <%- colors[i] %>"></span>
              <span class="plh data"><%- groups[i] %></span>
            </div>
          <% } %>
        </div>
      </div>
    
    <% if (showSummary) { %>
    <div class="small-12 medium-5 large-3 columns border-top-small ">
        <div class="medium-float-right ml1">
            <span class="diagram-summary-value color-deep-medium-navy"><%- summaryValue %></span>
            <span class="diagram-summary-label" aria-hidden="true"><%- summaryLabel %></span>
            <span class="show-for-sr"><%- summaryAlternativeLabel %></span>
        </div>
    </div>
    <% } %>
  </div>
`;

function roundNearestStep(num, step) {
  return Math.ceil(num / step) * step;
}

function prepareYAxisValues(yMax) {
  if (yMax < 10) {
    const step = 1;
    const roundedMax = roundNearestStep(yMax, step);
    return Array.from(Array(roundedMax + 1).keys()).map(v => v * step);
  }

  return undefined;
}

export default Backbone.View.extend({
  template: _.template(viewMarkup),
  initialize: function (options) {
    this.options = _.extend({}, {
      minRows: 12,
      showSummary: true,
      summaryLabel: 'Total',
      summaryAlternativeLabel: 'Total',
      hideXAxis: false,
      chartAlternativeLabel: "A bar chart",
      additionalColumns: [],
      groups: [],
      mapXAxisLabel: function (x) {
        return x;
      },
      mapTooltipDataLabel: function (x) {
        return x;
      }
    }, options);
  },
  show: function () {
    this.render();
  },
  render: function() {
    const groups = [this.model.attributes.data, ...this.options.additionalColumns]
    .filter(column => column && column.length > 0)
    .map(column => column[0])
    const $template = $(this.template(
      _.extend({
        colors: COLORS,
        groups: groups,
        summaryLabel: this.options.summaryLabel,
        summaryAlternativeLabel: this.options.summaryAlternativeLabel,
        summaryValue: 0,
        showSummary: this.options.showSummary,
        chartAlternativeLabel: this.options.chartAlternativeLabel
      },
        this.model.attributes)))
    this.$el.html($template);

    if (this.model) {
      const that = this;
      if (that.chart) {
        that.chart.destroy();
      }

      let yMax = 0;
      const data = that.model.attributes.data;
      if (that.options.yMax) {
        yMax = that.options.yMax;
      } else {
        var values = [...data];
        [, ...values] = values;
        yMax = Math.max(...values);
      }

      const yAxisValues = prepareYAxisValues(yMax);

      // data contains dataset label so length is always +1 to original dataset length
      const paddingRight =
        data.length > that.options.minRows ? 0.5 : (1.5 + that.options.minRows) - data.length;
      const barRatio = 0.66 * Math.min((data.length - 1) / (that.options.minRows), 1);
      // This is due an error that raises for data length 1
      const paddingLeft = data.length <= 2 ?
        1.5 - ((barRatio * (that.options.minRows - 1)) / that.options.minRows) : 0.5;

      that.chart = c3.generate({
        bindto: that.$el.find('.js-diagram-container').get(0),
        tooltip: {
          format: {
            title: function (x) {
              return that.options.mapTooltipDataLabel(that.model.attributes.xAxis[x]);
            },
          },
          position: function(diagramData, width, heigh, element) {
            const tooltipPosition =
              that.chart.internal.tooltipPosition(diagramData, width, heigh, element);
            let left = tooltipPosition.left;
            if (diagramData.length > 0 && that.selectedIndex !== diagramData[0].index) {
              const chart = that.$el.find('.js-diagram-container');
              const tooltipContainer = that.$el.find('.c3-tooltip-container');
              const expandedBar = that.$el.find('.c3-bar-' + diagramData[0].index);
              const expandedBarPosition = expandedBar.offset();
              const chartPosition = chart.offset();
              const chartWidth = chart.width();
              const tooltipContainerWidth = tooltipContainer.width();

              tooltipContainer.removeClass('right-arrow');
              tooltipContainer.removeClass('left-arrow');
              tooltipContainer.removeClass('no-arrow');
              tooltipContainer.removeClass('full-width');

              // Bar chart is smaller that arbitrary medium screen
              if (chartWidth < 300 && tooltipPosition) {
                tooltipContainer.addClass('no-arrow');
                tooltipContainer.addClass('full-width');
                return { top: tooltipPosition.top, left: 35 };
              }

              if (expandedBar && tooltipPosition && chartPosition
                  && chartWidth && tooltipContainerWidth && expandedBarPosition) {
                const barWidth = expandedBar[0].getBBox().width;
                const tooltipRightPosition =
                  (tooltipContainerWidth + expandedBarPosition.left + barWidth)
                  - chartPosition.left;

                  // For data of 1 element always display right arrow tooltip
                if (tooltipRightPosition > chartWidth && data.length > 2) {
                  left = expandedBarPosition.left - chartPosition.left
                    - tooltipContainerWidth - 10;

                  // In case tooltip would be displayed out of bounds
                  if (left < 10) {
                    tooltipContainer.addClass('no-arrow');
                    left = 35;
                  } else {
                    tooltipContainer.addClass('right-arrow');
                  }
                } else {
                  tooltipContainer.addClass('left-arrow');
                  // tooltip arrow width
                  left = (barWidth + 7 + expandedBarPosition.left) - chartPosition.left;
                }
                that.tooltipPosition = left;
                that.selectedIndex = diagramData[0].index;
              }
            } else if (that.tooltipPosition) {
              left = that.tooltipPosition;
            }

            return { top: tooltipPosition.top, left: left };
          }
        },
        data: {
          x: 'x',
          columns: [
            ['x', ...Array(data.length).keys()],
            data,
            ...that.options.additionalColumns
          ],
          type: 'bar',
          groups: that.options.groups,
          order: null
        },
        bar: {
            width: {
                ratio: barRatio
              }
        },
        grid: {
          y: {
            show: true
          }
        },
        axis: {
          y: {
            min: 0,
            padding: { bottom: 0 },
            tick: {
              values: yAxisValues,
              format: function(d) {
                if (Math.floor(d) != d) {
                  return undefined;
                }
                return d;
              },
            }
          },
          x: {
            show: !that.options.hideXAxis && window.innerWidth > 740,
            tick: {
              rotate: 75,
              format: function (x) {
                if (that.model.attributes.xAxis.length >= x) {
                  return that.options.mapXAxisLabel(that.model.attributes.xAxis[x]);
                }

                return x;
              },
              culling: {
                max: 50
              }
            },
            padding: {
              left: paddingLeft,
              right: paddingRight
            }
          }
        },
        legend: {
          show: false
        },
        color: {
          pattern: COLORS
        }
      });
    }

    return this;
  },
});

import 'backbone-validation';
import FormValidationView from './FormValidationView';

export default function(
  formSelector,
  validationRules,
  afterInitializationCallback,
  events) {
  const $form = $(formSelector).first();
  const prefix = $form.attr('id');
  const validation = {};
  /* eslint-disable */
  // This is a clean code solution and es lint is stupid
  for (const [key, value] of Object.entries(validationRules)) {
      validation[prefix + key] = value;
  }
  /* eslint-enable */

  const Model = Backbone.Model.extend({
      validation: validation,
  })

  const formView = new FormValidationView({
      el: '#' + prefix,
      model: new Model(),
      events: _.extend({}, FormValidationView.prototype.events, events)
  });
  if (afterInitializationCallback) afterInitializationCallback(formView, prefix);
}

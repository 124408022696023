/**
 * Ajax in views
 */
import AjaxView from './AjaxView';

const AddMemberView = AjaxView.extend({
  initialize: function() {
    this.model.on('change:user', this.updateSelectedUserDisplay, this);
    this.userDisplayTemplate = _.template($('#js-user-display-template').html());
  },


  lazyInitialize: function() {
    const view = this;
    const formatUserTemplate = _.template('<div><b><%= full_name %></b> <%= email %></div>');
    const $userSearchInput = this.$('.js-select2');

    window.loadjs.ready('select2', {
      success: function() {
        const $form = $userSearchInput.closest('form');
        const exclude = $form.find('#id_exclude').val();
        const url = $form[0].action;

        $userSearchInput.select2({
          ajax: {
            url: url,
            delay: 250,
            data: (params) => {
              const query = {
                q: params.term,
                exclude: exclude
              };

              return query;
            },
            processResults: data => ({
              results: data.map(function(user) {
                // update results to include text and id fields
                // that select2 expects
                return $.extend({}, {
                  id: user.pk,
                  text: user.full_name,
                }, user);
              })
            })
          },
          placeholder: 'User\'s name or email',
          minimumInputLength: 1,
          escapeMarkup: function (markup) { return markup; },
          templateResult: (user) => {
            if (user.loading) {
              return 'Searching...';
            }

            return formatUserTemplate(user);
          },
          templateSelection: user => user.full_name || user.text,
          allowClear: true
        });

        $userSearchInput.on('select2:select select2:unselect', function(e) {
          const params = e.params;

          if (params.data.selected) {
            view.setSelectedUser({
              pk: params.data.pk,
              full_name: params.data.full_name,
              email: params.data.email
            });
          } else {
            view.removeSelectedUser();
          }
        });
      }
    });

    this.updateSelectedUserDisplay(this.model, this.model.get('user'));

    // FIXME add this class in the backend widget templates instead
    const $fieldEls = this.$(':input').not('[type="checkbox"],[type="radio"],[type="hidden"],[class*="select2"]');
    $fieldEls.addClass('validation__field');
  },

  setSelectedUser: function(user) {
    this.model.set('user', user);
  },

  removeSelectedUser: function() {
    this.model.set('user', null);
  },

  updateSelectedUserDisplay: function(model, user) {
    const $select2 = this.$('.select2');
    const $select2Input = this.$('.js-select2');
    const $userInput = this.$('#add-membership-form').find('#id_user');

    if (user) {
      $userInput.val(user.pk);
      $select2.slideUp().blur();
      $select2.after(this.userDisplayTemplate(user));
      App.utils.clearValidationDisplay(this.el.querySelector('#id_q'));
    } else {
      $userInput.val(null);
      $select2Input.val(null).trigger('change');
      $select2.slideDown().focus();
      if (this.$('.selected-user__remove').length) {
        $select2.next().slideUp(function() {
          $(this).remove();
        });
      }
    }
  },

  events: {
    'submit #search-users-form': function(e) {
      e.preventDefault();
    },
    'change #id_role': function(e) {
      App.utils.clearValidationDisplay(e.currentTarget);
    },
    'submit #add-membership-form': 'ajaxSubmitForm',
    'click .remove-selected-user': 'removeSelectedUser'
  }
});

export default AddMemberView;

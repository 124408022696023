/**
 * Toggle field display view
 *
 * This component will display or hide fields for the
 * media component based on the select menu item
 */

const ToggleFieldDisplay = Backbone.View.extend({
  initialize() {
    this.$imageFields = Array.from($('.js-media-photo')).map(field => $(field).closest('.js-field-block'));
    this.$videoFields = Array.from($('.js-media-video')).map(field => $(field).closest('.js-field-block'));

    this.render();
  },

  render() {
    this.toggleFields();
  },

  toggleFields() {
    const contentType = $('#id_content_type').val();

    if (contentType === 'video') {
      this.showVideoFields();
    }

    if (contentType === 'image') {
      this.showImageFields();
    }
  },

  showVideoFields() {
    this.$videoFields.forEach(el => el.show());
    this.$imageFields.forEach(el => el.hide());
  },

  showImageFields() {
    this.$videoFields.forEach(el => el.hide());
    this.$imageFields.forEach(el => el.show());
  },

  // Toggle fields when selector value changes
  events: {
    'change #id_content_type': 'toggleFields'
  },
});

export default ToggleFieldDisplay;

/**
 * Google Autocomplete Service input
 *
 * @class
 */
import loadjs from 'loadjs';

const GeoCompleteView = Backbone.View.extend({
  /** Set to the Place a user clicks in select2 */
  currentPlace: new Backbone.Model(),

  /** Lazy loads select2 and Google Places API Library */
  initialize (options) {
    this.options = options;

    if (!this.$el.is('select')) {
      throw new Error('GeoCompleteView expected a select element');
    }

    if (!loadjs.isDefined('google.maps.places')) {
      loadjs(
        `https://maps.googleapis.com/maps/api/js?key=${window.GOOGLE_BROWSER_API_KEY}&libraries=places`,
        'google.maps.places');
    }

    if (!loadjs.isDefined('select2')) {
      loadjs([
        window.__STATIC__['js/select2.js'],
        window.__STATIC__['css/select2-theme.css']
      ], 'select2');
    }

    loadjs.ready(['google.maps.places', 'select2'], {
      success: this.render.bind(this)
    });
  },

  /** Sets up view after assets load, attaches select2 to input */
  render () {
    const placesAdapter = $.fn.select2.amd.require('select2/data/PlacesAdapter');
    const typesAllowed = this.options.typesAllowed || [
      'locality',
      'street_address',
      'administrative_area_level_1',
      'postal_code',
      'administrative_area_level_2'
    ];
    // undefined indicates all countries are allowed
    const countriesAllowed = this.options.countriesAllowed || undefined;
    const biasTowardsUsMainland = this.options.biasTowardsUsMainland;
    const allowDetailInCountries = [
      'United States',
      'Puerto Rico',
      'Guam',
      'American Samoa',
      'Northern Mariana Islands',
      'U.S. Virgin Islands',
      'USA',
      'USVI',
      'CNMI'
    ];

    this.$el.select2({
      dataAdapter: placesAdapter,
      places: {
        delay: 300,
        biasTowardsUsMainland,
        processResults: function(results) {
          return {
            results: results
              // If we limit the countries that are allowed make sure it's in the list
              .filter(place => _.some([
                countriesAllowed == undefined,
                _.contains(countriesAllowed, place.terms[place.terms.length - 1].value)
              ]))
              // Make sure that we are only allowing type country if not in the US territory list
              .filter(place => _.some([
                _.contains(allowDetailInCountries, place.terms[place.terms.length - 1].value),
                _.contains(place.types, 'country')
              ]))
              // Filter out the types that aren't allowed
              .filter(place => _.some(place.types, type => _.contains(typesAllowed, type)))
              // Set text for dropdown display
              .map(place => Object.assign({}, place, {
                text: place.description,
                id: place.place_id
              }))
          };
        }
      },
      placeholder: this.options.placeholder,
      minimumInputLength: 1,
      maximumSelectionLength: 1
    });

    this.$el.parents('.geo')

    const elem = this.$el[0]
    const id = elem.id;

    var geoInput = elem.parentElement.getElementsByClassName('select2-search__field')[0];
    if (!geoInput) {
      return;
    }
    geoInput.setAttribute('aria-required', elem.getAttribute('aria-required'));

    if (!id) {
      return;
    }

    geoInput.id = id + '-geo-input';
  },

  destroy () {
    this.$el.off('select2:select select2:unselect');
    this.$el.select2('destroy');
  },

  events: {
    'select2:open': function() {
      // add Google Logo in dropdown
      $('.select2-results__options').addClass('select2-results__google-logo');
    },
    'select2:select': function(e) {
      this.currentPlace.set(e.params.data);
    },
    'select2:unselect': function() {
      this.currentPlace.clear();
    }
  }
});

export default GeoCompleteView;

// =============================================================================
// Tab navigation Component
//
// This component will display or hide fields for the
// current tab item that was selected. This component assumes
// that you have an array of tab selectors and corresponding
// content selectors in the format ${selector}-content.
//
// @param {array} tabs - Array of tab selectors.
// @param {string} default - Selector for default active tab.
// =============================================================================

const TabNavigation = Backbone.View.extend({
  initialize(options) {
    this.tabs = options.tabs;
    this.default = options.default;
    this.render();
  },
  render() {
    Array.from(document.querySelectorAll(`.js-tab-content:not(.${this.default}-content)`)).forEach(function (el) {
      el.classList.add('sy-hide');
      el.style.display = 'none';
    });

    const activeClass = 'tab-nav__item--active';
    const defaultTab = document.querySelector(`.${this.default}`);

    if (defaultTab) {
      defaultTab.closest('.tab-nav__item').classList.add(activeClass);
    }

    const switchTab = function(tab, e) {
      // Remove current active class
      const nav = e.target.closest('.tab-nav');
      const activeTab = nav.querySelector('.tab-nav__item--active');
      if (activeTab) {
        activeTab.classList.remove(activeClass);
      }

      // Add active class to clicked navigation item
      e.target.closest('.tab-nav__item').classList.add(activeClass);

      // Hide all content sections
      Array.from(document.querySelectorAll(`.js-tab-content`)).forEach(function (_el) {
        _el.classList.add('sy-hide');
        _el.style.display = 'none';
      });

      // Remove hide from the current content section
      const currentSection = document.querySelector(`.${tab}-content`);
      currentSection.classList.remove('sy-hide');
      currentSection.style.display = 'flex';
    }

    this.tabs.forEach(function (tab) {
      const el = document.querySelectorAll(`.${tab}`)[0];
      if (el) {
        el.addEventListener('click', (e) => {
          switchTab(tab, e);
        });
        el.addEventListener('keydown', (e) => {
          if (e.keyCode == 13 || e.keyCode == 32) {
            e.preventDefault();
            switchTab(tab, e);
          }
        });
      }
    });
  }
});

export default TabNavigation;

import GeoCompleteView from './GeoCompleteView';

const GeoAutoCompleteSingleView = Backbone.View.extend({

  initialize () {
    const $locInput = this.$el.find('.js-loc');
    const $latInput = this.$el.find('.js-lat');
    const $lngInput = this.$el.find('.js-lng');
    const $gplaceInput = this.$el.find('.js-gplace');
    const $countryInput = this.$el.find('.js-country');
    const hiddenInputs = [$locInput, $latInput, $lngInput, $gplaceInput, $countryInput];
    const inputResp = this.$el.find('.js-places-response')[0];
    const geo = new GeoCompleteView({
      el: this.$el.find('.geo-select .js-select2'),
      collection: new Backbone.Collection(),
      placeholder: 'Any Location',
      typesAllowed: [
        'locality',
        'street_address',
        'administrative_area_level_1',
        'postal_code',
        'administrative_area_level_2',
        'country'
      ]
    });
    window.loadjs.ready(['google.maps.places', 'select2'], {
      success: function() {
        const placesService = new window.google.maps.places.PlacesService(inputResp);

        geo.$el.on('select2:opening', function() {
          if (geo.currentPlace.get('place_id')) {
            // clear out current input to prepare for new location search
            geo.currentPlace.clear();
            geo.$el.val(null).change();
            geo.$el.next('.select2').find('input').val(null);
          }
        });

        geo.$el.on('select2:select', function() {
          geo.$el.next('.select2').find('input').blur();
        });

        function clearInputValues() {
          hiddenInputs.forEach(input => input.val(''));
        }

        geo.$el.on('select2:unselect', function() {
          clearInputValues();
        });

        geo.currentPlace.on('change', function(place) {
          var placeId = place.get('place_id');

          if (placeId) {
            placesService.getDetails({
              placeId: placeId
            }, function(result, status) {
              if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                return;
              }

              clearInputValues();

              var countryCode = result.address_components.filter(function (element) {
                return _.contains(element.types, 'country');
              })[0].short_name;

              $countryInput.val(countryCode);

              var address = result.address_components[0];
              if (address.types[0] === 'country' ||
                  (address.types[0] === 'administrative_area_level_1' && countryCode == 'US')) {
                    // result is a state or country -> set text field value
                    if ($locInput) {
                      $locInput.val(address.long_name + (
                        countryCode === 'US' ? ', USA' : ''));
                    }
              } else {
                // result NOT a state or country -> set lat/lng values
                if ($latInput) {
                  var lat = result.geometry.location.lat();
                  $latInput.val(lat);
                }
                if ($lngInput) {
                  var long = result.geometry.location.lng();
                  $lngInput.val(long);
                }

                // set text field value
                if (result.types[0] === 'locality') {
                  // remove zipcode from input for non-zipcode searches
                  if ($locInput) {
                    $locInput.val(result.formatted_address.replace(/ \d{5}/, ''));
                  }
                } else if ($locInput) {
                    $locInput.val(result.formatted_address);
                }
              }

              if ($gplaceInput) {
                $gplaceInput.val(result.place_id);
              }
            });
          }
        });
      }
    });
  },
});

export default GeoAutoCompleteSingleView;

$('.js-loc').each(function() {
  var form = $(this).closest('form');
  if (form) {
    new GeoAutoCompleteSingleView({
      el: form
    });
  }
});

function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');

    window.removeEventListener('keydown', handleFirstTab);
    // eslint-disable-next-line no-use-before-define
    window.addEventListener('mousedown', handleMouseDownOnce);
  }
}

function handleMouseDownOnce() {
  document.body.classList.remove('user-is-tabbing');

  window.removeEventListener('mousedown', handleMouseDownOnce);
  window.addEventListener('keydown', handleFirstTab);
}

function elementFocused(e) {
  if ($('.bbm-modal:visible').length == 0) {
    window.lastFocusedElement = e.target;
  }
}

var focusableElementsString = "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]";

function elementBlurs(e) {
  var $gainsFocus = $(e.relatedTarget);
  var $loseFocus = $(e.target);

  if (document.body.classList.contains('user-is-tabbing') && $('.bbm-modal[modal-opened]:not([modal-closed])').length > 0) {
    if ($loseFocus.parents('.bbm-modal:visible').length > 0
      && e.relatedTarget
      && $gainsFocus.parents('.bbm-modal:visible, .js-tabloop-portal').length === 0) {
      if ($loseFocus.index() > $gainsFocus.index() || $gainsFocus.attr("id") === "invisible-modal-input") {
        $loseFocus.parents(".bbm-modal").find(focusableElementsString).first().focus();
      } else {
        $loseFocus.parents(".bbm-modal").find(focusableElementsString).last().focus();
      }
    }
  }
}

function focusOnLastClicked(element) {
  const parents = $(element).closest("[data-dropdown]");
  if (parents.length > 0) $(parents).foundation('toggle');
}

function onModalOpen(modal) {
  $(modal).attr("modal-opened", "modal-opened");
  if ($('#invisible-modal-input').length === 0) {
    $('.bbm-wrapper:visible').append('<input id="invisible-modal-input" role="none" type="text" style="width: 0px; overflow: hidden; padding: 0px; display: inline-block; border: none;" />');
  }

  var allFocusable = $(modal).find(focusableElementsString);
  allFocusable.each(function (index, element) {
    var $element = $(element);

    if ($element.attr("tabindex") > 0) {
      $element.attr("tabindex", "0");
    }
  });

  $(modal).attr("role", "dialog");
  $(modal).attr("aria-modal", "true");

  $(modal).find('.bbm-modal__title, .h3, .js-autofocus').focus();
}

function onModalClose(modal) {
  $(modal).attr("modal-closed", "modal-closed");
  if (window.lastFocusedElement && document.body.classList.contains('user-is-tabbing')) {
    focusOnLastClicked(window.lastFocusedElement);
    window.lastFocusedElement.focus();
  }
}

$('.js-close-modal').click(function (e) {
  const modal = e.target.closest('.modal');
  onModalClose(modal);
})

$('body').on('animationend', '.bbm-modal:not([modal-opened])', function (e) {
  onModalOpen(e.target);
});

$('body').on('animationend', '.bbm-modal[modal-closed]', function (e) {
  const modal = e.target.closest('.modal');
  $(modal).removeAttr("modal-closed");
  $(modal).removeAttr("modal-opened");
});

$('body').on('animationstart', '.bbm-modal[modal-opened]', function (e) {
  onModalClose(e.target);
});

export { handleFirstTab, elementFocused, elementBlurs };

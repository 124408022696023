const viewMarkup = `
<div role="alert" class="js-notification" style="display: none;">
    <div class="notification notification--<%- status %>">
        <div class="notification__message" data-test="banner-notification">
            <span class="notification__status">
            <% if (title) { %>
                <%- title %>
            <% } else if (status === "success") { %>
                Success!
            <% } else if (status === "warning") { %>
                Warning!
            <% } else { %>
                Error!
            <% } %>
            </span>
            <%- message %>
        </div>
        <a class="notification__dismiss js-dismiss-notification hide-for-no-js" role="button">
            <svg class="svg-icon svg-icon--white" role="presentation" ><use xlink:href="/static/svg/sprite/symbol/sprite.svg#icon--close"></use></svg>
            <span class="show-for-sr">Dismiss</span>
        </a>
    </div>
</div>
`;

export const notificationEventBus = _.extend({}, Backbone.Events);

export const NotificaitonEventView = Backbone.View.extend({
    template: _.template(viewMarkup),
    el: $("#notification_toast"),
    initialize: function(options) {
        _.bindAll(this, "showNotification");
        options.vent.bind("showNotification", this.showNotification);
    },
    showNotification: function(notification) {
        this.model = notification;
        this.render();
    },
    render: function() {
        if (this.model) {
            const $template = $(this.template(
                _.extend({title: '', status: 'success', message: ''}, this.model)))
            this.$el.append($template);
            $template.slideDown();
        }

        return this;
    },
    dismissNotification: function(e) {
        e.preventDefault();

        const $notification = $(e.currentTarget).closest('.js-notification');
        $notification.slideUp(function () {
            $notification.remove();
        });
    },
    events: {
        "click .js-dismiss-notification": "dismissNotification"
    },
  });

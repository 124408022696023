/**
 * Slug view
 */

import Awesomplete from 'awesomplete';

const SlugView = Backbone.View.extend({
  initialize (options) {
    this.options = options;
    this.$content_type = this.$el.closest('.js-form').find("select[id^='id_components'][id$='content_type']");
    this.render();
  },

  render () {
    if (!this.awesomplete) {
      this.awesomplete = new Awesomplete(this.el, {
        filter: function() {
          return true; // disable filter, use all of backend results
        },
        minChars: 2
      });
    }

    return this;
  },

  getSuggestions (query) {
    const data = {
      content_type_id: this.$content_type.val(),
      q: query
    };
    $.get(this.options.url, data, (res) => {
      const list = res;
      this.awesomplete.list = list;
      this.awesomplete.evaluate();
    });
  },

  events: {
    'focus': function(e) {
      const val = e.currentTarget.value;

      if (val && val.length >= this.awesomplete.minChars) {
        this.getSuggestions(val);
      }
    },

    'blur': function() {
      if (this.awesomplete) {
        this.awesomplete.list = [];
      }
    },

    'keyup': _.debounce(function(e) {
      const val = e.currentTarget.value;

      if (val && val.length >= this.awesomplete.minChars) {
        this.getSuggestions(val);
      } else {
        this.awesomplete.list = [];
      }
    }, 300),

    'awesomplete-selectcomplete': function() {
      this.awesomplete.close();
    }
  }
});


export default SlugView;


/**
 * Auto initialize
 */
$('.js-slug-search').each(function() {
  const $this = $(this);

  new SlugView({
    el: this,
    url: $this.data('url'),
  });
});

/**
 * Controls for a table with batch selection actions
 */
const BatchTableView = Backbone.View.extend({
  $selectableElements: null, // all elements that can be selected go here

  $actionElements: null, // buttons that trigger actions

  initialize () {
    this.$toggle = this.$el.find('.js-batch-select__toggle');
    this.$selectableElements = this.$el.find('.js-batch-select__selectable');
    this.$actionElements = this.$el.find('.js-batch-select__action');
    this.$actionElements.prop('disabled', true); // disable buttons by default if user has javascript
    this.$toggle.parent().addClass('disabled');
    this.checkAll();
    this.checkOne();
  },

  checkAll () {
    if (this.$toggle.is(':checked')) {
      this.$actionElements.prop('disabled', false);
      this.$toggle.parent().removeClass('disabled');
      this.$selectableElements.prop('checked', true);
    } else {
      this.$actionElements.prop('disabled', true);
      this.$toggle.parent().addClass('disabled');
      this.$selectableElements.prop('checked', false);
    }
  },

  checkOne () {
    const numberChecked = this.numberChecked();

    if (numberChecked > 0) {
      this.$actionElements.prop('disabled', false);
      this.$toggle.parent().removeClass('disabled');

      if (numberChecked === this.$selectableElements.length) {
        this.$toggle.prop('checked', true);
      } else {
        this.$toggle.prop('checked', false);
      }
    } else {
      this.$actionElements.prop('disabled', true);
      this.$toggle.parent().addClass('disabled');
      this.$toggle.prop('checked', false);
    }
  },

  numberChecked () {
    // function to check number of elements currently checked
    return this.$selectableElements.filter(':checked').length;
  },

  events: {
    'change .js-batch-select__toggle': 'checkAll',
    'change .js-batch-select__selectable': 'checkOne'
  }
});

export default BatchTableView;

/**
 * Validation
 *
 * All errors are considered warnings until the user has submitted to the
 * backend. The backend is the arbiter of validation, so nothing is considered
 * "erroneous" until the backend says so. Plus, this makes it easier to validate
 * required fields that are allowed to be empty on submission.
 */

import 'backbone-validation';
import moment from 'moment';
import { handleValidationErrors, handleValidationSuccess } from './';

Backbone.Validation.configure({
  labelFormatter: 'label'
});

const messages = Backbone.Validation.messages;
const labelFormatters = Backbone.Validation.labelFormatters;

// custom validators
_.extend(Backbone.Validation.validators, {
  /** Updates the built-in acceptance validator to support Django */
  acceptance: function(val, attr, accept, model) {
    if (_.isString(val)) {
      val = val.toLowerCase(); // support Django ;)
    }
    if (val !== 'true' && (!_.isBoolean(val) || val === false)) {
      return this.format(messages.acceptance, labelFormatters.label(attr, model));
    }
    return undefined;
  },

  /** Validates value is a date format */
  date: function(val, attr, required, model) {
    // check date
    const date = moment(val);
    if (!date.isValid()) {
      return messages.date;
    }

    // check required
    return this.required(val, attr, required, model);
  },

  /** Validates value is a date format and after minDate */
  minDate: function(val, attr, minDate, model) {
    // check date
    const dateError = this.date(val, attr, undefined, model);
    if (dateError) {
      return dateError;
    }

    // check min date
    if (moment(minDate).isAfter(val)) {
      return messages.minDate.replace('{1}', minDate);
    }
    return undefined;
  },

  /** Validates value is a date format and before maxDate */
  maxDate: function(val, attr, maxDate, model) {
    // check date
    const dateError = this.date(val, attr, undefined, model);
    if (dateError) {
      return dateError;
    }

    // check max date
    if (moment(maxDate).isBefore(val)) {
      return messages.maxDate.replace('{1}', maxDate);
    }
    return undefined;
  },

  /** Validates that at least one checkbox in a named set is checked */
  requiredCheckboxes: function(val, attr, required, model) {
    const isChecked = Backbone.$(`[name="${attr}"]`).is(':checked');
    return this.required(isChecked ? true : null, attr, required, model);
  },

  /** Validates that all checkboxes in a named set is checked */
  requiredAllCheckboxes: function(val, attr, required, model) {
    const allChecked = Backbone.$(`[name="${attr}"]`).not(':checked').length === 0;
    return this.required(allChecked ? true : null, attr, required, model);
  },

  maxRangeInput: function(val, attr, settings, model, computed) {
    let dependency = settings.attr;
    if (typeof dependency === 'function') {
      dependency = dependency(model);
    }
    const error = this.maxFromInput(val, attr, dependency, model, computed);
    if (error) {
      return `This field must be less than or equal to  ${settings.label}`;
    }

    return undefined;
  },

  minRangeInput: function(val, attr, settings, model, computed) {
    let dependency = settings.attr;
    if (typeof dependency === 'function') {
      dependency = dependency(model);
    }
    const error = this.minFromInput(val, attr, dependency, model, computed);
    if (error) {
      return `This field must be greater than or equal to  ${settings.label}`;
    }

    return undefined;
  },

  /** Validates value is digits and less than max provided by named input */
  maxFromInput: function(val, attr, maxValNameAttr, model, computed) {
    // check digits
    const digitsError = this.pattern(val, attr, 'digits', model);
    if (digitsError) {
      return digitsError;
    }
    // check not over maxValNameAttr value
    const maxVal = parseInt(computed[maxValNameAttr], 10);
    if (!Number.isNaN(maxVal)) {
      const maxError = this.max(val, attr, maxVal, model);
      if (maxError) {
        return maxError;
      }
    }
    return undefined;
  },

  /** Validates value is digits and greater than min provided by named input */
  minFromInput: function(val, attr, minValNameAttr, model, computed) {
    // check digits
    const digitsError = this.pattern(val, attr, 'digits', model);
    if (digitsError) {
      return digitsError;
    }
    // check not under minValNameAttr value
    const minVal = parseInt(computed[minValNameAttr], 10);
    if (!Number.isNaN(minVal)) {
      const minError = this.min(val, attr, minVal, model);
      if (minError) {
        return minError;
      }
    }
    return undefined;
  },

  /** Validates value is date and before date provided by named input */
  maxFromDateInput: function(val, attr, maxDateNameAttr, model, computed) {
    // check not later than maxDateNameAttr date
    const dateError = this.maxDate(val, attr, computed[maxDateNameAttr], model);
    if (dateError) {
      return dateError;
    }
    return undefined;
  },

  /** Validates value is date and after date provided by named input */
  minFromDateInput: function(val, attr, minDateNameAttr, model, computed) {
    // check not earlier than minDateNameAttr date
    const dateError = this.minDate(val, attr, computed[minDateNameAttr], model);
    if (dateError) {
      return dateError;
    }
    return undefined;
  },

  requiredCheckboxWithOther: function(value, attr, requiredAny) {
    let valueSelector = "other";
    if (requiredAny.valueSelector) {
      valueSelector = requiredAny.valueSelector;
    }
    let optionalOther = false;
    if (requiredAny.optionalOther) {
      optionalOther = requiredAny.optionalOther;
    }
    const isChecked = Backbone.$(`[name="${attr}"]`).is(':checked');
    const $otherCheckbox = Backbone.$(`[name="${attr}"][value="${valueSelector}"]`);
    const $otherField = Backbone.$(`[name="${attr}_additional_text"]`);
    const $errorList = $otherCheckbox.closest(".validation").find('.errorlist');
    if ($otherCheckbox.is(':checked') && !$otherField.val() && !optionalOther) {
        const errorList = $errorList.detach();
        $otherCheckbox.siblings('.choice-listing__label').append(errorList);
        return messages.required;
    }

    if (!isChecked && requiredAny) {
        const errorList = $errorList.detach();
        $otherCheckbox.closest(".validation").append(errorList);
    }

    if (!requiredAny) $errorList.hide();

    if (isChecked && $otherField.siblings('.errorlist').length > 0) $errorList.hide();

    return undefined;
},

requiredTextWithOther: function(value, attr) {
  const $otherField = Backbone.$(`[name="${attr}"]`);
  const $errorList = $otherField.closest(".validation").find('.errorlist');
  const errorList = $errorList.detach();
  $otherField.closest('.choice-listing__label').append(errorList);
  return !$otherField.val() ? messages.required : undefined;
},

});

// custom patterns
_.extend(Backbone.Validation.patterns, {
  zip: /^(\d{5}([\-]\d{4})?)$/, // http://html5pattern.com/Postal_Codes
  // Allow any common phone number special chars. Must have 10 or 11 digits.
  phone: /^(?:[\+\(\)\.\-\s]*[0-9]){10,11}$/,
  slug: /^[\w-]{1,30}$/,
  twitterUrl: /^(http(s)?:\/\/)?(www\.)?twitter\.com\/[^\s]+$/,
  facebookUrl: /^(http(s)?:\/\/)?((www|m)\.)?facebook\.com\/[^\s]+$/,
  instagramUrl: /^(http(s)?:\/\/)?(www\.)?instagram\.com\/[^\s]+$/,
  linkedinUrl: /^(http(s)?:\/\/)?(www\.)?linkedin\.com\/[^\s]+$/,
  url: /^(http(s)?:\/\/)?[^\s]+\.[^\s]+$/,
  ein: /^\d{2}-\d{7}$/,
  year: /^\d{4,}$/,
  email: /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))){2,}$/i
});

// custom messages
_.extend(Backbone.Validation.messages, {
  required: 'This field is required',
  acceptance: 'This field must be accepted',
  min: 'This field must be greater than or equal to {1}',
  max: 'This field must be less than or equal to {1}',
  range: 'This field must be between {1} and {2}',
  length: 'This field must be {1} characters',
  minLength: 'This field must be at least {1} characters',
  maxLength: 'This field must be at most {1} characters',
  rangeLength: 'This field must be between {1} and {2} characters',
  oneOf: 'This field must be one of: {1}',
  equalTo: 'This field must be the same as {1}',
  digits: 'This field must only contain digits',
  number: 'This field must be a number',
  email: 'This field must be a valid email',
  url: 'This field must be a valid URL',
  inlinePattern: 'This field is invalid',

  // custom validators messages
  date: 'This field must be a valid date',
  minDate: 'This field must be after {1}',
  maxDate: 'This field must be before {1}',

  // custom pattern messages
  zip: 'This field must be a valid zip code',
  phone: 'Phone number must have 10 digits',
  slug: 'This field must only contain letters, numbers, hyphens or underscores',
  twitterUrl: 'A valid Twitter url should look like this: https://twitter.com/username',
  facebookUrl: 'A valid Facebook url should look like this: https://www.facebook.com/username',
  instagramUrl: 'A valid Instagram url should look like this: https://instagram.com/username',
  linkedinUrl: 'A valid Linkedin url should look like this: https://www.linkedin.com/in/username',
  ein: 'A valid EIN number should look like this: 12-3456789',
  year: 'This field must be a valid 4 digit year'
});

// feedback display logic callbacks
_.extend(Backbone.Validation.callbacks, {
  valid: function(view, attr) {
    let value = view.model.attributes[attr];
    const $el = view.$(`[name="${attr}"]`);
    if ($el.is(':checkbox')) value = view.$(`[name="${attr}"]:checked`).length > 0;
    handleValidationSuccess($el, value);
  },
  invalid: function(view, attr, error) {
    const $el = view.$(`[name="${attr}"]`);
    handleValidationErrors($el, [error]);
  }
});

import Glide from '@glidejs/glide/dist/glide.js'
import FadingVideoView from '../components/FadingVideoView';
import VideoView from '../components/VideoView';
import { linearScale, initCarousel } from '../utils';

const isTouchDevice = () => (
  ('ontouchstart' in window) || (navigator.maxTouchPoints > 0));

function initInformationModals(selector) {
  $(selector).each(function() {
    var $this = $(this);
    var slug = $this.attr('id');

    // find all .flex-card children
    // take their href for identifying modal content template
    var hrefs = $this.find('.flex-card').map(function(idx, el) {
      return $(el).attr('href');
    }).get();

    var Modal = Backbone.Modal.extend({
      template: _.template($('#' + slug + '-modal-template').html()),
      viewContainer: '.info-carousel-modal',
      views: (function() {
        var views = {};
        hrefs.forEach(function(href, idx) {
          views[idx] = {
            view: _.template($(href + '-template').html())
          };
        });
        return views;
      }()),
      cancelEl: '.js-cancel',
      // Prevent modal to be close by enter keyup, but still allows esc quite
      beforeSubmit: () => false,
      onRender: function() {
        $(document.body).addClass('overflow-hidden');
      },
      onDestroy: function() {
        $(document.body).removeClass('overflow-hidden');
      }
    });

    var modalView;
    var eventSelectors = hrefs.map(function(href) {
      return '[href="' + href + '"]';
    });

    // init content modals
    $(document).on('click', eventSelectors.join(','), function(e) {
      e.preventDefault();

      // lazy init modal
      if (!modalView) {
        modalView = new Modal();
      }

      // open modal
      const elementIndex = parseInt(e.currentTarget.dataset.elementId, 10);
      $('#' + slug + '-modal').html(modalView.render({ _index: elementIndex }).el);

      // modal header shrinks on scroll
      var $top = modalView.$el.find('.flex-card__header');
      var $bottom = modalView.$el.find('.flex-card__detail');
      var initialTopHeight = $top.height();
      var initialBottomHeight = $bottom.height();
      var minTopHeight = Math.min(initialTopHeight, 115);

      const modalTitle = document.getElementsByClassName('js-autofocus')[0];
      modalTitle.setAttribute('tabindex', 0);
      modalTitle.focus();

      $bottom.on('scroll', _.throttle(function() {
        var topHeightDelta = linearScale(Math.min($bottom.scrollTop(), minTopHeight), 0,
          initialTopHeight, 0, initialBottomHeight);

        _.defer(function() {
          $top.css('height', initialTopHeight - topHeightDelta);
          $bottom.css('height', initialBottomHeight + topHeightDelta);
        });
      }, 10));
    });
  });
}

function initStoryCarousels(selector) {
  $(selector).each(function() {
    var $this = $(this);
    var slug = $this.data('id');

    const avatarsSlider = document.getElementById('js-avatars-' + slug);
    const storiesSlider = document.getElementById('js-stories-' + slug);
    const storiesCount = avatarsSlider.getElementsByClassName('glide__slide').length
    var startAt = storiesCount < 5 ? Math.ceil(storiesCount / 2) - 1 : 2;

    const avatarsGlide = new Glide('#js-avatars-' + slug, {
      type: 'slider',
      autoplay: false,
      startAt: startAt,
      focusAt: 'center',
      perView: 5,
      gap: -50,
      breakpoints: {
        740: {
          perView: 3
        }
      }
    });

    const setArrowsDisability = function(currentIndex) {
      const leftArrow = avatarsSlider.getElementsByClassName('glide__arrow--left')[0];
      const rightArrow = avatarsSlider.getElementsByClassName('glide__arrow--right')[0];
      if (currentIndex === 0) {
        leftArrow.setAttribute('disabled', 'disabled');
        leftArrow.setAttribute('tabindex', '-1');
        leftArrow.style.pointerEvents = 'none';
        leftArrow.style.opacity = 0.33;
      } else {
        leftArrow.removeAttribute('disabled');
        leftArrow.setAttribute('tabindex', '0');
        leftArrow.style.pointerEvents = null;
        leftArrow.style.opacity = 1;
      }
      if (currentIndex === (storiesCount - 1)) {
        rightArrow.setAttribute('disabled', 'disabled');
        rightArrow.setAttribute('tabindex', '-1');
        rightArrow.style.pointerEvents = 'none';
        rightArrow.style.opacity = 0.33;
      } else {
        rightArrow.removeAttribute('disabled');
        rightArrow.setAttribute('tabindex', '0');
        rightArrow.style.pointerEvents = null;
        rightArrow.style.opacity = 1;
      }
    };

    avatarsGlide.mount();

    const storiesGlide = new Glide('#js-stories-' + slug, {
      type: 'slider',
      autoplay: false,
      dragThreshold: false,
      swipeThreshold: false,
      startAt: startAt,
      gap: 0
    });

    const setStoriesHeight = function() {
      const slideHeight = storiesSlider.querySelector(`[data-index="${storiesGlide.index}"]`).offsetHeight;
      const glideTrack = storiesSlider.getElementsByClassName('glide__track')[0];
      if (slideHeight != glideTrack.offsetHeight) {
        glideTrack.style.height = `${slideHeight}px`;
      }
    };

    const adjustAvatarAccessability = (currentIndex) => {
      const sliderWidth = avatarsSlider.offsetWidth;
      const slides = avatarsSlider.querySelectorAll(`.avatar-slider__avatar`);
      var maximumVisibleSlides = sliderWidth / slides[0].parentElement.offsetWidth;
      maximumVisibleSlides = Math.round(maximumVisibleSlides);

      for (var i = 0; i < slides.length; i++) {
        const leftVisibleIndex = currentIndex - (maximumVisibleSlides / 2);
        const rightVisibleIndex = currentIndex + (maximumVisibleSlides / 2);

        if (leftVisibleIndex <= i && i <= rightVisibleIndex) {
          slides[i].setAttribute('tabindex', '0');
        } else {
          slides[i].setAttribute('tabindex', '-1');
        }
      }
    };

    const disableOtherStoriesVideo = (currentIndex) => {
      const slides = storiesSlider.querySelectorAll(`.glide__slide`);
      for (var i = 0; i < slides.length; i++) {
        const video = slides[i].querySelector(`.js-video`);
        const link = slides[i].querySelector(`a.story-slider__title`);
        // eslint-disable-next-line no-continue
        if (i === currentIndex) {
          if (video !== null) {
            video.style.display = 'block';
            const plyrWrapper = video.querySelector(`.plyr__video-wrapper`);
            if (plyrWrapper !== null) { plyrWrapper.style.paddingBottom = '56.34%'; }
          }
          if (link !== null) { link.setAttribute('tabindex', '0'); }
        } else {
          if (video !== null) { setTimeout(() => { video.style.display = 'none'; }, 100); }
          if (link !== null) { link.setAttribute('tabindex', '-1'); }
        }
      }
    };

    storiesGlide.on('build.after', function() {
      setStoriesHeight();
    });

    storiesGlide.on('run', function() {
      disableOtherStoriesVideo(storiesGlide.index);
      setStoriesHeight();
    });

    storiesGlide.mount();

    avatarsGlide.on('run', function() {
      storiesGlide.go('=' + avatarsGlide.index);
      setArrowsDisability(avatarsGlide.index);
      adjustAvatarAccessability(avatarsGlide.index);
      window.onresize = () => { adjustAvatarAccessability(avatarsGlide.index); };
    });

    setArrowsDisability(startAt);
    adjustAvatarAccessability(startAt);
    disableOtherStoriesVideo(startAt);

    avatarsSlider.querySelector('.glide__slides')
      .addEventListener('click', function(e) {
        const slide = e.target.closest('.glide__slide');
        if (!slide) {
          return;
        }
        const index = parseInt(slide.getAttribute('data-index'), 10);
        if (index === 0 || index >= 0) {
          avatarsGlide.go('=' + index);
        }
      }
    );

    $this.find('.vimeo').each(function(index, el) {
      new FadingVideoView({
        el: el
      });
    });
  });
}

function initGallery(selector) {
  $(selector).each(function() {
    var slider = document.querySelector('.gallery__slider');
    var items = document.querySelectorAll('.gallery__item');

    if (!items.length) { return; }

    var rows = 2;
    var verticalSpace = 10;
    var horizontalSpace = (items[0].offsetWidth / 2) + 5;

    // Variables for slider position
    var posX = 0;
    var pixelsPerFrame = 0.5; // How many pixels to move per frame

    var itemWidth = items[0].offsetWidth;
    var sliderWidth = (itemWidth * (items.length / rows)) + (itemWidth + horizontalSpace);
    slider.style.width = sliderWidth + 'px';

    var requestId = null;
    var delayId = null;

    function animate() {
      if (posX >= -sliderWidth) {
        slider.style.left = posX + 'px';
        posX -= pixelsPerFrame;
      } else {
        posX = window.outerWidth;
      }

      requestId = window.requestAnimationFrame(animate);
    }

    function displayPopoverForItem(item) {
      var $logo = $(item);

      // add new tooltip
      var $tooltip = $(_.template($('#js-gallery-tooltip-template').html())({
        name: $logo.data('name'),
        location: $logo.data('location')
      }));

      if (!$logo.data('location')) {
        $tooltip.find('.tooltip__body').hide();
      }

      // calculate space left and right of logo
      var left = $logo.offset().left;
      var right = window.innerWidth - (left + $logo.width());
      var top = $logo.offset().top;

      // place popover
      var $popover = $('.gallery__popover');
      var popoverWidth = $popover.width();
      var popoverStyles = {};
      $tooltip.removeClass('tooltip--left tooltip--right tooltip--top');
      $popover.html($tooltip.addClass('tooltip--visible'));
      $popover.css({opacity: 1, transition: 'opacity 300ms ease-in-out'});

      if ((popoverWidth / 2) > left) {
        // tooltip on right
        $tooltip.addClass('tooltip--right');
        popoverStyles = {
          left: left + ($logo.outerWidth() * $logo.data('scale')) + 20,
          top: (top + Math.abs((($popover.outerHeight() - ($logo.outerHeight() * $logo.data('scale'))) / 2))) - 5
        };
      } else if ((popoverWidth / 2) > right) {
        // tooltip on left
        $tooltip.addClass('tooltip--left');
        popoverStyles = {
          left: left - $popover.outerWidth() - 20,
          top: (top + Math.abs((($popover.outerHeight() - ($logo.outerHeight() * $logo.data('scale'))) / 2))) - 5
        };
      } else {
        // tooltip on top
        $tooltip.addClass('tooltip--top');
        popoverStyles = {
          left: left - (($popover.outerWidth() - ($logo.outerWidth() * $logo.data('scale'))) / 2),
          top: top - $popover.outerHeight() - 26
        };
      }

      $popover.css(popoverStyles);

      function hidePopover () {
        $tooltip.removeClass('tooltip--visible');
        $popover.removeAttr('style');
      }

      _.defer(function () {
        $logo.one('mouseleave', hidePopover);
        $(window).one('touchstart', hidePopover);
      });
    }

    // initial item positioning, randomized
    var randomMemo = []; // memo for avoiding the same random placement
    var randomLogos = [];
    $.each(items, function(index, item) {
      // random startX position
      var randomOffset = Math.floor(Math.random() * items.length);
      while (randomMemo.indexOf(randomOffset) !== -1) {
        // find unused random position
        randomOffset = Math.floor(Math.random() * items.length);
      }
      randomMemo.push(randomOffset);
      randomLogos[randomOffset] = item;

      item.style.right = ((slider.offsetWidth - item.offsetWidth) - (randomOffset * horizontalSpace)) + 'px';

      // startY position is staggered, with extra space for box shadow
      item.style.top = 10 + ((item.offsetHeight + verticalSpace) * (randomOffset % rows)) + 'px';

      if (isTouchDevice()) {
        item.addEventListener('touchstart', function(e) {
          // first tap stops animation and opens popover, allows for second tap to navigate to link
          if (!$('.gallery__popover .sy-tooltip').hasClass('tooltip--visible')) {
            e.preventDefault();
            window.cancelAnimationFrame(requestId);

            if (delayId) {
              window.clearTimeout(delayId);
            }

            displayPopoverForItem(item);

            // any other subsequent taps restart animation
            _.defer(function () {
              $(window).one('touchstart', function () {
                delayId = setTimeout(function () {
                  requestId = window.requestAnimationFrame(animate);
                }, 150);
              });
            });
          }
        }, false);

      } else {
        // stop animation and display popover on item mouseenter
        item.addEventListener('mouseenter', function() {
          window.cancelAnimationFrame(requestId);
          item.style.transition = 'transform 250ms ease-in-out';
          item.style.transform = `scale(${item.dataset.scale * 1.1})`;

          if (delayId) {
            window.clearTimeout(delayId);
          }

          displayPopoverForItem(item);
        }, false);

        // delayed start animation on item mouseleave
        item.addEventListener('mouseleave', function() {
          item.style.transition = 'transform 250ms ease-in-out';
          item.style.transform = 'scale(' + item.dataset.scale + ')';
          delayId = setTimeout(function () {
            requestId = window.requestAnimationFrame(animate);
          }, 150);
        }, false);
      }
    });

    // after items are randomly placed, randomly scale
    $.each(randomLogos, function(index, item) {

      var scale = Math.max(0.5, Math.random());

      // compare the delta from previous scales
      if (index !== 0 && index !== 1) {
        var prevScale = randomLogos[index - 2].dataset.scale;
        while (Math.abs(scale - prevScale) < 0.2) {
          scale = Math.max(0.5, Math.random());
        }
      }
      // scale item and set scale attribute
      item.style.transform = 'scale(' + scale + ')';
      item.dataset.scale = scale;
    });

    // start animation
    requestId = window.requestAnimationFrame(animate);
  });
}

function initListingMapFilters(selector) {
  $(selector).each(function(idx, el) {
    var states = new Backbone.Collection(
      // collect state elements and names from dom
      $(el).find('.listing-filter__location').get().map(function(state) {
        return {
          $el: $(state),
          name: state.innerText
        };
      })
    );

    var FilterView = Backbone.View.extend({
      initialize: function() {
        Backbone.View.prototype.initialize.call(this);

        // prevent scrolling on filter list until we can see DOM below it so
        // the user can scroll past the filters if they choose
        var $window = $(window);
        var $filterList = this.$('.listing-filter__locations');
        $filterList.css('overflow-y', 'hidden');

        $filterList.on('click', function() {
          $filterList.css('overflow-y', 'scroll');
        });

        $(document).on('scroll', _.throttle(function() {
          var windowScrollTop = $window.scrollTop();
          var offsetBottom = $filterList.offset().top + $filterList.height();
          var bottomDistanceFromTop = offsetBottom - windowScrollTop;

          if ((bottomDistanceFromTop + 16) < $window.height()) {
            $filterList.css('overflow-y', 'scroll');
          } else {
            $filterList.css('overflow-y', 'hidden');
          }
        }, 50));
      },
      events: {
        'input input': function(e) {
          // filter states by input
          var val = e.currentTarget.value;
          var matchRe = new RegExp(val, 'i');

          this.collection.map(function(state) {
            if (state.get('name').search(matchRe) >= 0) {
              state.get('$el').show();
            } else {
              state.get('$el').hide();
            }
          });

          // show/hide clear button
          this.$('button').toggle(!!val);
        },

        'click button': function() {
          // clear input
          this.$('input').val('').trigger('input');
          this.$('input').focus();
        }
      }
    });

    (() => new FilterView({
      el: el,
      collection: states
    }))();
  });
}

function initVideos(selector, options) {
  $(selector).each(function (_, el) {
      (() => {
          new VideoView({
            el: $(el),
            plyrOpts: options
          });
      })();
  });
}

function disableFooterFocus() {
  window.addEventListener('load', () => {
    document.querySelectorAll('.js-disable-footer-focus').forEach((carouselRoot) => {
      const disableCardFooterFocus = (timeout = 501) => {
        setTimeout(() => {
          carouselRoot.querySelectorAll('.search-card__footer').forEach((cardFooter) => {
            cardFooter.setAttribute('tabindex', '-1');
          });
        }, timeout);
      };

      disableCardFooterFocus(1);

      carouselRoot.querySelectorAll('.slick-arrow').forEach((arrow) => {
        arrow.addEventListener('click', () => disableCardFooterFocus(501));
      });

    });
  });
}

function disableScreenReaderLinkFocus () {
  document.querySelectorAll('.js-links-disable-tabindex').forEach((linksToDisable) => {
    linksToDisable.querySelectorAll('a').forEach(
        (element) => {
          element.setAttribute('tabindex', -1);
        });
    });
}

function getParentSection(component) {
  var parent = component.parentElement;
  while (parent.tagName !== 'SECTION') {
    parent = parent.parentElement;
    if (parent.tagName === 'MAIN') {
      return null;
    }
  }
  return parent;
}

function changeHeadingForFirstComponent() {
  // Function swap first h2 element into h1 when page is missing Headline.
  var headlinelessPage = document.querySelector('.js-replace-headline');
  if (headlinelessPage === null) { return; }
  // Get first element with h2 element.
  var component = headlinelessPage.querySelector('h2');
  if (component === null) { return; }
  // we want ot get section before make any changes for h2 tag,
  // for recursive headline change.
  var h2Section = getParentSection(component);
  component.outerHTML = component.outerHTML.replace('h2', 'h1');
  // Recursive swap for components with h3 elements.
  if (h2Section === null) { return; }
  h2Section.querySelectorAll('h3').forEach((subHeadline) => {
    subHeadline.outerHTML = subHeadline.outerHTML.replace('h3', 'h2');
  });
}

window.addEventListener('load', changeHeadingForFirstComponent);

$('.js-carousel').each(function() {
  const srCarouselId = this.getAttribute('data-sr-carousel-id');
  var srCarousel = document.getElementById(srCarouselId);

  initCarousel(this, {
    singleSlide: this.hasAttribute('data-single-slide-carousel'),
    randomSlides: this.hasAttribute('data-random-slides-carousel'),
    srCarousel: srCarousel
  });
  disableFooterFocus();
  document.querySelectorAll('.slick-prev').forEach((button) => {
    button.addEventListener('click', () => {
      setTimeout(disableScreenReaderLinkFocus, 500);
    });
  });
  document.querySelectorAll('.slick-next').forEach((button) => {
    button.addEventListener('click', () => {
      setTimeout(disableScreenReaderLinkFocus, 500);
    });
  });
});

var videoOptions = {
  controls: [],
  youtube: {'controls': 1},
  vimeo: {
    'controls': true,
    'transparent': true,
  }
};

var bgVideoOptions = {
  clickToPlay: false,
  controls: [],
  loop: { active: true },
  autoplay: true,
  isBgVideo: true,
  volume: 0,
  muted: true,
};

initInformationModals('.js-carousel-modals');
initStoryCarousels('.js-story-carousel');
initGallery('.js-gallery');
initListingMapFilters('.js-listing-map-filter');
initVideos('.js-video', videoOptions);
initVideos('.js-video-background', bgVideoOptions);

window.addEventListener('load', () => {
  const backgroundVideos = $('.js-video-background');
  for (let index = 0; index < backgroundVideos.length; index++) {
    const iframe = backgroundVideos[index].getElementsByTagName('iframe')[0];
    iframe.setAttribute('tabindex', -1);
    iframe.setAttribute('aria-hidden', true);
  }
});
window.addEventListener('load', disableScreenReaderLinkFocus);


import AddMemberView from './AddMemberView';
import AjaxView from './AjaxView';
import AnchorSubNavView from './AnchorSubNavView';
import AnchorWindowView from './AnchorWindowView';
import BatchTableView from './BatchTableView';
import ContentFilterView from './ContentFilterView';
import FadingVideoView from './FadingVideoView';
import FileUploadView from './FileUploadView';
import FormsetView from './FormsetView';
import FormValidationView from './FormValidationView';
import createFormsetValidationView from './FormsetValidationView';
import GeoAutoCompleteSingleView from './GeoAutoCompleteSingleView';
import GeoCompleteView from './GeoCompleteView';
import ImageFocalModal from './ImageFocalModal';
import KeywordCompleteView from './KeywordCompleteView';
import ManyToManyView from './ManyToManyView';
import MessageThreadView from './MessageThreadView';
import ReadMoreView from './ReadMoreView';
import RemoveMultipleMemberView from './RemoveMultipleMemberView';
import SlugView from './SlugView';
import TabNavigation from './TabNavigation';
import ToggleFieldDisplay from './ToggleFieldDisplay';
import UnsavedDataModalView from './UnsavedDataModalView';
import {notificationEventBus, NotificaitonEventView} from './NotificationToast';
import ContentMenu from './ContentMenu';
import Diagram from './Diagram';


export default {
  AddMemberView,
  AjaxView,
  AnchorSubNavView,
  AnchorWindowView,
  BatchTableView,
  ContentFilterView,
  FadingVideoView,
  FileUploadView,
  FormsetView,
  FormValidationView,
  GeoAutoCompleteSingleView,
  GeoCompleteView,
  ImageFocalModal,
  KeywordCompleteView,
  ManyToManyView,
  MessageThreadView,
  ReadMoreView,
  RemoveMultipleMemberView,
  SlugView,
  TabNavigation,
  ToggleFieldDisplay,
  UnsavedDataModalView,
  NotificaitonEventView,
  notificationEventBus,
  ContentMenu,
  createFormsetValidationView,
  Diagram
};

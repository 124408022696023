import serialize from './serialize.js';

/**
 * Utilities
 */

export const validationContainer = '.validation';
export const validationErrorsClass = 'errorlist';
export const validationWarningClass = 'validation--warning';
export const validationErrorClass = 'validation--error';
export const validationSuccessClass = 'validation--success';
export const validationFieldClass = 'validation__field';
const validationFieldWithError = '.validation.validation--error:not(.group_label), .validation.validation--warning:not(.group_label)'
const validationFieldWithSuccess = '.validation.validation--success:not(.group_label)'

/**
 * Filters HTTP methods for those that require CSRF header
 */
export function csrfSafeMethod (method) {
  // these HTTP methods do not require CSRF protection
  return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}

function unsetAriaInvalidAttr ($group) {
  if ($group.find(`.${validationErrorsClass}:visible`).length === 0) {
    $group.find('input, select, textarea').attr("aria-invalid", false)
  }
}

/**
 * Verifies if the field is a combined field and sets appropriate validation state to label
 *
 * @param {Object} $el- jQuery object of the validation field
 */
 function validateCombinedFields($el) {
  const $group = $el.parents('.validation--group');
  const $invalidFields = $group.find(validationFieldWithError);
  const $groupLabel = $group.find(".group_label");
  if ($group.length > 0 && $invalidFields.length > 0) {
    $groupLabel.removeClass('validation--success');
    $groupLabel.removeClass('validation--error');
    $groupLabel.removeClass('validation--warning');
    if ($invalidFields.hasClass('validation--error')) {
      $groupLabel.addClass('validation--error');
    } else {
      $groupLabel.addClass('validation--warning');
    }

    $group.find('input, select, textarea').attr("aria-invalid", true)
  } else {
    const $validFields = $group.find(validationFieldWithSuccess);
    if ($validFields.length > 0) $groupLabel.addClass('validation--success');
    $groupLabel.removeClass('validation--error');
    $groupLabel.removeClass('validation--warning');
    unsetAriaInvalidAttr($group);
  }
}

/**
 * Displays all feedback messges in the errors array in an errorlist on a
 * field
 *
 * @param {Object} $el- jQuery object of the validation field
 * @param {Array} errors - list of errors to append to errorlist
 */
export function handleValidationErrors ($el, errors) {
  const $group = $el.closest(validationContainer);

  if ($group.length) {
    let $errorList = $group.find(`.${validationErrorsClass}`);
    $errorList.finish();
    $group.removeClass(`${validationErrorClass} ${validationSuccessClass} ${validationWarningClass}`);

    if (errors.some(error => error.includes('This field is required'))) {
      $errorList.removeClass(validationErrorClass);
      $group.addClass(validationWarningClass);
    } else {
      $errorList.addClass(validationErrorClass);
      $group.addClass(validationErrorClass);
    }

    $group.find('input, select, textarea').attr("aria-invalid", true)

    if ($errorList.length) {
      // remove previous errors
      $errorList.empty();
    } else {
      // new errorlist
      $errorList = $(`<ul class="${validationErrorsClass}"></ul>`).hide().appendTo($group);
    }

    errors.forEach(function(error) {
      $errorList.append(`<li>${error}</li>`);
    });
    $errorList.slideDown(400, () => validateCombinedFields($el));
  } else {
    console.log(`error Warning: no validation group found for ${$el.attr('name')}`);
  }
}

/**
 * Removes all feedback messages on a field.
 *
 * @param {Object} $el- jQuery object of the validation field
 */
export function handleValidationSuccess ($el, value) {
  const $group = $el.closest(validationContainer);

  if ($group.length) {
    const $errorList = $group.find(`.${validationErrorsClass}`);
    if ($errorList.length > 0) {
      $errorList.finish().slideUp(400, () => {
        $group.removeClass(`${validationErrorClass} ${validationWarningClass}`);
        if (value) $group.addClass(validationSuccessClass);
        else $group.removeClass(validationSuccessClass);
        validateCombinedFields($el);

        unsetAriaInvalidAttr($group);
      });
    } else {
      $errorList.finish();
      $group.removeClass(`${validationErrorClass} ${validationWarningClass}`);
      if (value) $group.addClass(validationSuccessClass);
      else $group.removeClass(validationSuccessClass);
      validateCombinedFields($el);
    }

    unsetAriaInvalidAttr($group);
  }
}

function showOtherIfChecked($checkboxElement, $fieldElement, otherRequired) {
  if ($checkboxElement.is(':checked')) {
    $fieldElement.show();
    $fieldElement.attr('aria-required', otherRequired);
  } else {
    $fieldElement.hide();
    $fieldElement.attr('aria-required', false);
    $fieldElement.val(null);
  }
}

export function setupCheckboxOtherField (checkboxSelector, fieldSelector, otherRequired = true) {
  const $checkboxElement = $(checkboxSelector);
  const $fieldElement = $(fieldSelector)
  if ($checkboxElement.length > 0 && $fieldElement.length > 0) {
    $checkboxElement.on("change", () => showOtherIfChecked($checkboxElement, $fieldElement, otherRequired))
    showOtherIfChecked($checkboxElement, $fieldElement, otherRequired);
  }
}

export function setupRadioOtherField (
  radioSelector,
  otherRadioSelector,
  fieldSelector,
  otherRequired = true
) {
  const $radioElement = $(radioSelector);
  const $radioOtherElement = $(otherRadioSelector);
  const $fieldElement = $(fieldSelector)
  if ($radioElement.length > 0 && $fieldElement.length > 0 && $radioOtherElement.length > 0) {
    $radioElement.on("change", () => showOtherIfChecked($radioOtherElement, $fieldElement, otherRequired))
    showOtherIfChecked($radioOtherElement, $fieldElement, otherRequired);
  }
}

/**
 * Initialize data for adding/removing members
 *
 * @param {Object} Modal - Modal class used to define view
 * @param {Object} addMembershipEl - DOM element for Add button
 * @param {Number} index - Index of the Remove Member Modal
 */
export function setupMemberView (Modal, addMembershipEl, index) {
  const loadEvents = ['mouseover', 'click', 'focus'];
  const loadAssets = () => {
    // bundle for select2 should only be called once
    loadEvents.forEach((loadEvent) => {
      addMembershipEl.removeEventListener(loadEvent, loadAssets, false);
    })
    window.loadjs([
      window.__STATIC__['js/select2.js'],
      window.__STATIC__['css/select2-theme.css']
    ], 'select2');
  };

  loadEvents.forEach((loadEvent) => {
    addMembershipEl.addEventListener(loadEvent, loadAssets, false);
  });
  addMembershipEl.addEventListener('click', (e) => {
    e.preventDefault();
    const modalView = new Modal({
      model: new Backbone.Model({
        title: 'Add Team Member',
        templateUrl: e.currentTarget.href
      })
    });

    $('#settings-team-modal').html(modalView.render({ _index: 0 }).el);
  });

  document.querySelector('#remove-membership-form').addEventListener('submit', function(e) {
    e.preventDefault();
    const modalView = new Modal({
      model: new Backbone.Model({
        title: 'Remove Team Member',
        templateUrl: e.currentTarget.action,
        params: serialize(e.currentTarget)
      })
    });

    $('#settings-team-modal').html(modalView.render({ _index: index }).el);
  });

  (() => new App.components.BatchTableView({
    el: '.js-batch-select'
  }))();
}

/**
 * Clears current validation status and styles
 *
 * @param {Object} el - Element for the validation field
 */
export function clearValidationDisplay (el) {
  const group = el.closest(validationContainer);
  if (group) {
    group.classList.contains(`${validationErrorClass}`) ?
      group.classList.remove(`${validationErrorClass}`) : null;
    group.classList.contains(`${validationWarningClass}`) ?
      group.classList.remove(`${validationWarningClass}`) : null;
    group.classList.contains(`${validationSuccessClass}`) ?
      group.classList.remove(`${validationSuccessClass}`) : null;
    const errorList = group.querySelectorAll(`.${validationErrorsClass}`);
    errorList && Array.from(errorList).forEach((error) => {
      $(error).slideUp();
    });
  } else {
    console.log(`Warning: no validation group found for ${el.getAttribute('name')}`);
  }
}

// finds static files provided by django. TODO use webpack for this where possible
export function getStatic (file) {
  if (window.__STATIC__) {
    return window.__STATIC__[file];
  }

  return null;
}

// ouputs svg element for icon sprite with optional extra classes
// and aria role (similar to inline_svg_sprite templatetag)
export function inlineSvgSprite (icon, extraClasses = '', role = 'presentation', alt) {
  const sprites = getStatic('svg/sprite/symbol/sprite.svg') || '/static/svg/sprite/symbol/sprite.svg';
  return `
    <svg class="svg-icon ${extraClasses}" role="${role}" alt="${alt || ''}">
      <use xlink:href="${sprites}#icon--${icon}"></use>
    </svg>`;
}

// scales number clamped by min max to range min max
export function linearScale (num, scaleMin, scaleMax, rangeMin, rangeMax) {
  return (((scaleMax - scaleMin) * (num - rangeMin)) / (rangeMax - rangeMin)) + scaleMin;
}

// emulates jQuery parents() with find()
export const getParents = (elem, selector) => {
  const parents = [];
  const selectorType = selector && selector.charAt(0);

  const isClassSelector = (el, type, elSelector) => (
    type === '.' && el.classList.contains(elSelector.substr(1)));

  const isIDSelector = (el, type, elSelector) => (
    type === '#' && el.id === elSelector.substr(1));

  const isDataAttributeSelector = (el, type, elSelector) => (
    type === '[' && (
      el.hasAttribute(elSelector.substr(1, elSelector.length - 1))));

  const isTagSelector = (el, elSelector) => (
    el.tagName.toLowerCase() === elSelector);

  /**
   * NOTE: Skipping the [initial-expression] of for-loop construct,
   * as no iterator is used or needed
   */
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (selectorType) {
      if (isClassSelector(elem, selectorType, selector) ||
          isIDSelector(elem, selectorType, selector) ||
          isDataAttributeSelector(elem, selectorType, selector) ||
          isTagSelector(elem, selectorType, selector)) {
        parents.push(elem);
      }
    } else {
      parents.push(elem);
    }
  }

  return parents.length === 0 ? null : parents;
};

// Add target blank to all external urls in cms components
export const addTargetAttributeToExternalLinks = (selector) => {
  Array.from(document.querySelectorAll(selector))
    .filter(link => link.hostname)
    .filter(link => link.hostname !== window.location.hostname)
    .forEach(link => link.setAttribute('target', '_blank'));
};

// Reposition the Y coordinate of mobile tool tip so it is not cutoff the screen
export const repositionToolTip = (e) => {
  // this is used to fix the issue of the browser auto-refocusing to the
  // input field when clicking on a tooltip for mobile
  e.preventDefault();

  const toolTipIcon = e.target.closest('.icon-tooltip');
  const toolTipContainer = toolTipIcon.parentNode.getElementsByClassName('icon-tooltip__container icon-tooltip__container--mobile')[0];

  if (!toolTipContainer) return;

  toolTipContainer.style.top = '';
  toolTipContainer.style.display = 'block';

  const {
    top: toolTipIconTop,
    height: toolTipIconHeight
  } = toolTipIcon.getBoundingClientRect();

  const {
    top: toolTipContainerTop,
    height: toolTipContainerHeight
  } = toolTipContainer.getBoundingClientRect();

  const displayToolTipBelowIcon = (
    toolTipContainerHeight > ((toolTipIconTop + toolTipIconHeight) - 10));

  if (displayToolTipBelowIcon) {
    toolTipContainer.style.top = `${(toolTipIconTop - toolTipContainerTop) + toolTipIconHeight}px`;
  }
  else {
    toolTipContainer.style.top = `${(-toolTipContainerHeight + toolTipIconTop) - toolTipContainerTop - 5}px`;
  }
};

export const shuffleSlides = (carousel, srCarousel = undefined) => {
  var children = $(carousel).children().clone();
  $(carousel).empty();

  if (srCarousel) {
    var srCarouselChildren = $(srCarousel).children().clone();
    $(srCarousel).empty();
  }

  var availIndexes = _.shuffle(_.range(children.length));
  for (var i = 0; i < availIndexes.length; i++) {
    var index = availIndexes[i];
    var child = children[index];
    $(carousel).append(child);
    // Case only when we have screen reader list alternative.
    if (srCarousel) {
      // eslint-disable-next-line block-scoped-var
      var srCarouselChild = srCarouselChildren[index];
      // Update SR information about which item number we are on.
      srCarouselChild.getElementsByClassName('js-item-number')[0].innerHTML = `Item number ${i + 1}.`;
      $(srCarousel).append(srCarouselChild);
    }
  }
}

export const initCarousel = (el, { singleSlide, randomSlides, srCarousel }) => {
  var $el = $(el);

  let varWidthMedium = true;
  let varWidthLarge = true;
  let numSlidesMedium = 2;
  let numSlidesLarge = 3;

  if (randomSlides) {
    if (srCarousel) {
      srCarousel = $(srCarousel);
      shuffleSlides(el, srCarousel);
    }
    else {
      shuffleSlides(el);
    }
  }

  if (singleSlide) {
    varWidthMedium = false;
    varWidthLarge = false;
    numSlidesMedium = 1;
    numSlidesLarge = 1;
  }

  // init carousel
  $el.slick({
    arrows: false,
    rows: 0,
    prevArrow: `
      <button type="button" class="slick-prev">
        ${inlineSvgSprite('angle-left', '', 'img', 'previous matches')}
      </button>`,
    nextArrow: `
      <button type="button" class="slick-next">
        ${inlineSvgSprite('angle-right', '', 'img', 'next matches')}
      </button>`,
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 740,
        settings: {
          arrows: true,
          centerMode: false,
          variableWidth: varWidthMedium,
          slidesToShow: numSlidesMedium
        }
      },
      {
        breakpoint: 1100,
        settings: {
          arrows: true,
          centerMode: false,
          variableWidth: varWidthLarge,
          slidesToShow: numSlidesLarge
        }
      }
    ]
  });
}

export const googlesIdSyncValid = (sourceName, targetName, validationModel) => {
  var googleIdsInput = $('[name=' + sourceName + ']');
  var locationInput = $('[name=' + targetName + ']');
  locationInput.val(googleIdsInput.val());
  validationModel.checkValid(targetName);
};

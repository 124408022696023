/**
 * Unsaved Data Modal View
 * If a user tries to navigate away from a form after changing any inputs,
 * prompt the user to save changes before redirecting to the new window location.
 * Note: The selector parameter is the form to bind input change events to.
 */

const modal = `
<div id="js-confirm-navigation-modal-template">
    <div class="bbm-modal__topbar">
        <h3 tabindex="0" class="bbm-modal__title">Are you sure you want to leave?</h3>
        <button class="bbm-modal__close js-cancel" aria-label="close modal" type="button">
            <svg class="svg-icon " role="img" >
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${window.__STATIC__ ? window.__STATIC__['svg/sprite/symbol/sprite.svg'] : ""}#icon--close"></use>
            </svg>
        </button>
    </div>
    <div class="bbm-modal__section">
        <p>Hey there! It looks like you are in the middle of editing and have unsaved changes. Save before you go!</p>
        <div class="sy-button-group text-center">
            <button class="button js-cancel" type="button">Stay on page</button>
            <button class="button button--secondary ml1 js-submit" type="button">
                <span>Leave</span>
                <span class="show-for-medium">&nbsp;without saving</span>
            </button>
        </div>
    </div>
</div>
`;

const UnsavedDataModalView = Backbone.View.extend({
  initialize(options) {
    this.render();

    this.inputDataChanged = false;
    this.forceNavigate = false;

    const self = this;

    const ConfirmNavigationModal = Backbone.Modal.extend({
      template: modal,
      submitEl: '.js-submit',
      cancelEl: '.js-cancel',
      // submit is automatically triggered if you open this modal by keyboard
      // I have no idea why, it's probably some kind of black magic
      // we need to ignore that event
      keyControl: false,

      onRender() {
        // Close mobile menus
        const mmenu = $('#mm-menu-user').data('mmenu');
        if (mmenu) {
          mmenu.close();
        }
      },

      beforeSubmit() {
        self.forceNavigate = true;
        const buttons = this.el.querySelectorAll('button');
        Array.from(buttons).forEach((button) => {
          button.disabled = true;
        });
        this.submit();
        return false;
      },

      cancel() {
        this.activeElement.focus();
        this.forceNavigate = false
      },

      handleEscKey(e) {
        var code = e.keyCode || e.which;
        // This is handling ESC keyboard input for the modal
        if (code == 27) {
          this.$el.find('.js-cancel').click();
        }
      },

      events: {
        // Capture user navigating away from the form
        'keydown': 'handleEscKey',
      }
    });

    this.modal = new ConfirmNavigationModal();

    // Bind change input events to the form selector
    this.$el.find(`${options.selector} :input:not(:submit)`).on('change', () => { this.inputDataChanged = true; });
    this.$el.find(`${options.selector}`).on('submit', () => { this.forceNavigate = true; });

    // Capture user closing window, displays a native browser dialog
    $(window).on('beforeunload', (e) => {
      if (self.inputDataChanged && !self.forceNavigate) {
        const confirmationMessage = 'Are you sure you want to leave?';
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
      return undefined;
    });
  },

  render() {
    if (!document.getElementById('js-confirm-navigation-modal')) {
      const modalDiv = document.createElement('div');
      modalDiv.id = 'js-confirm-navigation-modal';
      this.$el.append(modalDiv);
    }
  },

  preventUnsavedFormDataNavigation(e) {
    if (this.inputDataChanged) {
      const sendUserToRequestedLocation = () => {
        // Send user to their requested location
        window.location = e.currentTarget.href;
      };

      this.modal.submit = sendUserToRequestedLocation;
      this.modal.activeElement = document.activeElement;
      var element = this.modal.render().el;
      $('#js-confirm-navigation-modal').html(element);

      $(".is-mobile.is-open").trigger("close");
      return false;
    }
    return true;
  },

  preventUnsavedFormDataSearch(e) {
    if (this.inputDataChanged && this.forceNavigate === false) {
      const submitRequestedForm = () => {
        const $form = $(e.currentTarget);
        $form.off('submit');
        $form.submit();
        return false;
      };

      this.modal.submit = submitRequestedForm;
      this.modal.activeElement = document.activeElement;
      var element = this.modal.render().el;
      $('#js-confirm-navigation-modal').html(element);

      $(".search__button[disabled]").removeAttr("disabled");

      return false;
    }
    return true;
  },

  preventUnsavedFormDataKeyboard(e) {
    if (e.keyCode == 13) {
      return this.preventUnsavedFormDataNavigation(e);
    }
    return true;
  },

  events: {
    // Capture user navigating away from the form
    'click a[href]:not([href^="#"])': 'preventUnsavedFormDataNavigation',
    'keydown a[href]:not([href^="#"])': 'preventUnsavedFormDataKeyboard',
    'submit form[action="/search/"]': 'preventUnsavedFormDataSearch'
  }
});

export default UnsavedDataModalView;

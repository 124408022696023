/**
 * Application entry point, use for setup and initialization
 */

import 'jquery';
import 'foundation';
import 'foundation.accordion';
import 'foundation.dropdown';
import 'foundation.interchange';
import 'foundation.magellan';
import 'foundation.offcanvas';
import 'backbone';
import _ from 'underscore';
import 'svgxuse';

import 'backbone.modal';
import '@glidejs/glide';
import 'slick-carousel';

import Cookies from 'js-cookie';
import './utils/lazyload';
import { csrfSafeMethod } from './utils';
import App from './App';
import { handleFirstTab, elementFocused, elementBlurs } from './focus';

const notificationView =
  new App.components.NotificaitonEventView({vent: App.components.notificationEventBus})
notificationView.render();

window._ = _;
// Status notifications!
window.addEventListener('load', () => {
  const statuses = document.getElementsByClassName('js-display-banner-on-load');
  for (let i = 0; i < statuses.length; i++) {
    statuses[i].style.display = 'flex';
  }
});

// Handle Alerts Close button
const alertCloseButtons = document.querySelectorAll('.js-alert-close');
alertCloseButtons.forEach(function(alertCloseButton) {
alertCloseButton.addEventListener('click', () => {
    const alertDiv = alertCloseButton.closest('.alert');
    alertDiv.remove();
    });
});

// Focus
window.addEventListener('keydown', handleFirstTab);
window.addEventListener('focusin', elementFocused);
window.addEventListener('focusout', elementBlurs);

/**
 *
 * Patch for CVE-2015-9251
 * NOTE: Remove after migration to jQuery v3.0.0 or higher
 */
$.ajaxPrefilter((opts) => { opts.crossDomain && (opts.contents.script = false); });

/**
 * Set jQuery to send CSRF token when required and always cache ajaxed scripts
 * https://docs.djangoproject.com/en/1.7/ref/contrib/csrf/#ajax
 */
$.ajaxSetup({
  beforeSend: function (xhr, settings) {
    const csrftoken = Cookies.get('csrftoken');
    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
      xhr.setRequestHeader('X-CSRFToken', csrftoken);
    }
  },
  cache: true
});

/**
 * Set Raven to report any ajax errors, see https://docs.sentry.io/clients/javascript/tips/#same-origin
 */
if (window.Raven) {
  $(document).ajaxError(function (event, jqXHR, ajaxSettings, thrownError) {
    window.Raven.captureMessage(thrownError || jqXHR.statusText, {
      extra: {
        type: ajaxSettings.type,
        url: ajaxSettings.url,
        data: ajaxSettings.data,
        status: jqXHR.status,
        error: thrownError || jqXHR.statusText,
        response: jqXHR.responseText ? jqXHR.responseText.substring(0, 100) : ''
      }
    });
  });
}

$(document).foundation();

/**
 * FIXME stop-gap until we can refactor cms.js into components
 */
require('./lib/cms');

/**
 * Initialize application
 */
const el = document.querySelector('#root');

function handleTabloop(e) {
  const $target = $(".js-tabloop");
  if (e.keyCode == 9) {
    if ($target.find(":focus").length === 0
        || ($(".js-focus-last:focus").length > 0 && !e.shiftKey)
        || ($(".js-focus-first:focus").length > 0 && e.shiftKey)) {
      e.shiftKey ? $target.find(".js-focus-last").focus() : $target.find(".js-focus-first").focus();
      e.preventDefault();
    }
  }
}

$.fn.tabloop = function() {
  const $target = $(this);
  $target.toggleClass("js-tabloop")
  if ($target.hasClass("js-tabloop")) {
    $("body").on("keydown", handleTabloop);
  } else {
    $("body").off("keydown", handleTabloop);
  }
};

$.fn.focusFirst = function() {
  const $target = $(this);
  $target.find(".js-focus-first").focus();
};

$.fn.hasScrollBar = function() {
  return this.get(0).scrollHeight > this.height();
}

new App({
  el
});

/**
 * Takes an input box and looks at it's content to show/hide elements based on that input
 * Note: matchAgainstSelector is optional, if not provided it'll take the textContent
 * of the displayElementSelector to match against. matchAgainstSelector is only
 * necessary when targetting nested content
 * noResultsSelector is also optional, if passed it'll hide the no results div
 * and show/hide it if the subset has no data to show
 */

const ContentFilterView = Backbone.View.extend({
  initialize (options) {
    this.filterInput = this.el.querySelector('.js-filter-input');
    this.matchAgainstSelector = options.matchAgainstSelector;
    this.displayEls = Array.from(this.el.querySelectorAll(options.displayElsSelector));

    const noResultsEl = this.el.querySelector(options.noResultsSelector);
    if (noResultsEl) {
      this.noResultsEl = noResultsEl;
      this.noResultsEl.classList.add('sy-hide');
      this.noResultsSearchTerm = this.noResultsEl.querySelector('.js-searchterm');
    }

    // clear the search if the user comes back from a prev page
    this.filterInput.value = '';
  },
  filterContent (e, search) {
    const searchTerm = search || this.filterInput.value;

    // show hide items based on search
    this.displayEls.forEach((el) => {
      const matchEl = this.matchAgainstSelector ? el.querySelector(this.matchAgainstSelector) : el;
      if (!matchEl || !matchEl.textContent) {
        return;
      }

      if (matchEl.textContent.toLowerCase().indexOf(searchTerm) > -1) {
        el.classList.remove('sy-hide');
      } else {
        el.classList.add('sy-hide');
      }
    });

    if (!this.noResultsEl) {
      return;
    }

    // show hide no results el
    const visibleItems = this.displayEls.filter(el => !el.classList.contains('sy-hide'));

    if (visibleItems.length === 0) {
      this.noResultsEl.classList.remove('sy-hide');
    } else if (visibleItems.length > 0) {
      this.noResultsEl.classList.add('sy-hide');
    }

    if (this.noResultsSearchTerm) {
      const displayValue = searchTerm.length > 15
                              ? `${searchTerm.substr(0, 15)}...`
                              : searchTerm;
      this.noResultsSearchTerm.textContent = displayValue;
    }
  },
  events: {
    'input .js-filter-input': 'filterContent'
  }
});

export default ContentFilterView;


/**
 * Auto initialize
 */
Array.from(document.querySelectorAll('.js-content-filter')).forEach((el) => {
  new ContentFilterView({
    el,
    displayElsSelector: '.js-filter-pane',
    matchAgainstSelector: '.js-org-name',
    noResultsSelector: '.js-no-results'
  });
});

const viewMarkup = `
  <div id="js-focal-modal">
    <div class="bbm-modal__section">
      <div class="focal-point">
        <div class="row">
          <div class="columns small-12 medium-5">
            <div class="focal-point__box">
              <h4 tabindex="0" class="js-header js-autofocus">Click the image to adjust the focal point</h4>
              <p class="focal-point__helper p0">Drag the (+) pointer over the primary point of attention in this photo.</p>

              <div class="relative focal-point__canvas js-point-canvas" style="background-image: url(<%= image %>)">
                <img src="<%= image %>" alt="Image" class="focal-point__placeholder" />
                <div class="js-focal-point-indicator focal-point__indicator"></div>
              </div>

              <div class="focal-point__buttons">
                <button class="button button--secondary button--small-expand" id="js-cancel-submit" type="button">Cancel</button>
                <button class="button button--small-expand" id="js-focal-submit" type="submit">OK</button>
              </div>
              <input name="focal-input" type="hidden" value="<%= value %>" disabled>
            </div>

            <p class="focal-point__helper">Setting the focus point will adjust how this image scales when users have smaller browsers or use tablet/mobile devices.</p>
          </div>

          <div class="columns small-12 medium-7">
            <h1 class="heading--micro color-deep-medium-navy">Image Previews</h1>
            <h6>Square</h6>
            <div class="js-focal-preview focal-point__preview focal-point__preview--mobile" style="background-image: url(<%= image %>);">
              <img src="<%= image %>" class="invisible">
            </div>

            <h6>Cover</h6>
            <div class="js-focal-preview focal-point__preview focal-point__preview--tablet" style="background-image: url(<%= image %>);">
              <img src="<%= image %>" class="invisible">
            </div>

            <h6>Sliver</h6>
            <div class="js-focal-preview focal-point__preview focal-point__preview--desktop" style="background-image: url(<%= image %>);">
              <img src="<%= image %>" class="invisible">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
`;

const ImageFocalModal = Backbone.Modal.extend({
  template: _.template(viewMarkup),
  keyControl: true,
  submitEl: '#js-focal-submit',
  cancelEl: '#js-cancel-submit',
  onRender() {
    $('body').css('overflow', 'hidden');
    this.$el.find('.bbm-modal').addClass('bbm-modal--wide');

    this.previews = this.$el.find('.js-focal-preview');
    this.updatePreviews();
    this.updateFocalPointIndicator();
  },
  beforeSubmit: function() {
    // send a setFocus event to whomever wants to listen to it
    // this way we can decouple what to do on set to make it more generic
    this.trigger('setFocus');
  },
  onDestroy() {
    $('body').css('overflow', 'visible');
  },
  initialize() {
    this.isMouseDown = false;

    this.model.on('change:value', () => {
      this.$el.find('input[name="focal-input"]').val(this.model.get('value'));
      this.updatePreviews();
    });
  },
  updatePreviews() {
    this.previews.css(
      'background-position',
      `${this.model.get('x') * 100}% ${this.model.get('y') * 100}%`
    );
  },

  canvasClick(e) {
    const $target = $(e.currentTarget);

    const x = Math.min(Math.max(e.offsetX / $target.width(), 0), 1);
    const y = Math.min(Math.max(e.offsetY / $target.height(), 0), 1);

    this.model.set({
      x,
      y,
      value: `${x},${y}`
    });

    this.updateFocalPointIndicator();
  },

  updateFocalPointIndicator() {
    const xPerc = this.model.get('x') * 100;
    const yPerc = this.model.get('y') * 100;

    this.$el.find('.js-focal-point-indicator').css({
      left: `${xPerc}%`,
      top: `${yPerc}%`
    });
  },

  events: {
    'mousedown .js-point-canvas': function(e) {
      this.isMouseDown = true;
      this.canvasClick(e);
    },
    'mouseup .js-point-canvas': function(e) {
      this.isMouseDown = false;
      this.canvasClick(e);
    },
    'mousemove .js-point-canvas': function(e) {
      if (this.isMouseDown) {
        this.canvasClick(e);
      }
    },
    'mouseleave .js-point-canvas': function(e) {
      if (this.isMouseDown) {
        this.isMouseDown = false;
        this.canvasClick(e);
      }
    }
  }
});

export default ImageFocalModal;

/**
 * Initialize
 */
const mountingDiv = document.createElement('div');
mountingDiv.id = 'js-focal-image-modal';
document.body.appendChild(mountingDiv);

$(document.body).on('click', '.js-image-focus-change', function (e) {
  const targetFor = e.currentTarget.getAttribute('for');

  const value = document.getElementById(e.currentTarget.getAttribute('for')).value;
  const x = value.split(',')[0];
  const y = value.split(',')[1];

  const model = new Backbone.Model({
    value,
    x,
    y,
    image: $(e.currentTarget).parents('.field-info').find('.js-uploaded-image')[0].src,
    targetFor
  });

  const modalView = new ImageFocalModal({
    title: 'Choose a focal point',
    model
  });

  modalView.on('setFocus', () => {
    document.getElementById(targetFor).value = modalView.$el.find('input[name="focal-input"]')[0].value;
  });

  $('#js-focal-image-modal').html(modalView.render().el);
});

/**
 * Lazy sizes extras, see https://github.com/aFarkas/lazysizes/blob/gh-pages/plugins/unveilhooks/ls.unveilhooks.js
 */

import 'lazysizes';

const regBgUrlEscape = /\(|\)|\s|'/;

const bgLoad = (url, cb) => {
  let img = document.createElement('img');
  img.onload = function() {
    img.onload = null;
    img.onerror = null;
    img = null;
    cb();
  };
  img.onerror = img.onload;
  img.src = url;

  if (img && img.complete && img.onload) {
    img.onload();
  }
};

document.addEventListener('lazybeforeunveil', function(e) {
  // exit out early if default is prevented
  if (e.defaultPrevented) {
    return;
  }

  if (e.target.preload == 'none') {
    e.target.preload = 'auto';
  }

  // handle data-bg
  let bg = e.target.getAttribute('data-bg');
  const isInterchange = !!(e.target.getAttribute('data-interchange')); // check if this element is handled by interchange

  if (!isInterchange && bg) {
    e.detail.firesLoad = true;

    const bgLoader = () => {
      bg = regBgUrlEscape.test(bg) ? JSON.stringify(bg) : bg;
      e.target.style.backgroundImage = `url(${bg})`;
      e.detail.firesLoad = false;
      lazySizes.fire(e.target, '_lazyloaded', {}, true, true);
    };

    bgLoad(bg, bgLoader);
  }

  // handle data-poster
  const poster = e.target.getAttribute('data-poster');

  // TODO data-src video sources https://github.com/ressio/lazy-load-xt/blob/master/src/jquery.lazyloadxt.video.js

  if (poster) {
    e.detail.firesLoad = true;

    const posterLoader = () => {
      e.target.poster = poster;
      e.detail.firesLoad = false;
      lazySizes.fire(e.target, '_lazyloaded', {}, true, true);
    };

    bgLoad(poster, posterLoader);
  }
});

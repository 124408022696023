const FadingVideoView = Backbone.View.extend({

  play () {
    var $play = this.$('.js-play');
    var $video = this.$('video');

    $play.fadeOut();
    $video[0].play();
    $video.attr('controls', true);

    $video.one('pause', function() {
      $video.removeAttr('controls');
      $play.fadeIn();
    });
  },

  events: {
    'click .js-play': 'play'
  }
});

export default FadingVideoView;

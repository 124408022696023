/**
 * Many to Many view
 */

const ManyToManyView = Backbone.View.extend({
  initialize(options) {
    this.url = window.location.origin + options.url;
    const self = this;
    self.$searchInput = $(this.el).find('.js-simple-search');

    // Deselect all manytomany options on laod
    $(this.el).find('.js-simple-search-options-selected option').each(function() {
      $(this).prop('selected', false);
    });

    // Get the max attribute if it exists
    const max = $('.js-simple-search-options-selected').attr('max');
    if (max) {
      this.max = parseInt(max, 10);
    }

    // On window load, pre-populate fields
    self.simpleSearch();
  },

  simpleSearch() {
    const $wrapper = this.$searchInput.closest('.js-manytomany-ajax-search');
    const $optionInput = $($wrapper.find('.js-simple-search-options')[0]);

    // Create comma separated list of excluded ids
    const excludes = Array.from($wrapper.find('.js-simple-search-options-selected option')).map(opt => opt.value);

    $.get(this.url, {
      model: this.$searchInput.attr('data-search-model'),
      q: this.$searchInput.val(),
      excludes: excludes.join(','),
      instance_id: this.$searchInput.attr('data-instance-id'),
    })
    .done(function(options) {
      $optionInput.empty();
      _.forEach(options, function(option) {
        $optionInput.append($('<option />', {'value': option[0], 'text': option[1]}));
      });
    });
  },

  selectItems(e) {
    const action = $(e.currentTarget).data('action');

    const $btn = $(e.currentTarget);
    const $wrapper = $btn.closest('.js-manytomany-ajax-search');
    const $selectedOptions = $($wrapper.find('.js-simple-search-options-selected'));

    const selector = (action == 'choose-all') ? '.js-simple-search-options option' : '.js-simple-search-options :selected';

    $wrapper.find(selector).each((idx, el) => {
      if (this.exceedsMax()) {
        return;
      }

      $selectedOptions.append($('<option />', {'value': el.value, 'text': el.text}));
      el.remove();
    });
    this.simpleSearch();
  },

  deselectItems(e) {
    const action = $(e.currentTarget).data('action');

    const $btn = $(e.currentTarget);
    const $wrapper = $btn.closest('.js-manytomany-ajax-search');
    const $availOptions = $($wrapper.find('.js-simple-search-options'));

    if (action === 'remove-all') {
      $wrapper.find('.js-simple-search-options-selected option').each(function() {
        $availOptions.append($('<option />', {'value': this.value, 'text': this.text}));
        this.remove();
      });
    } else if (action === 'remove-selected') {
      $wrapper.find('.js-simple-search-options-selected :selected').each(function() {
          $availOptions.append($('<option />', {'value': this.value, 'text': this.text}));
          this.remove();
      });
    }
    this.simpleSearch();
  },

  exceedsMax() {
    return $('.js-simple-search-options-selected option').length >= this.max;
  },

  events: {
    'keyup .js-simple-search': _.debounce(function () {
      this.simpleSearch(); }, 300),
    'click .js-choose-all': 'selectItems',
    'click .js-choose-selected': 'selectItems',
    'click .js-remove-all': 'deselectItems',
    'click .js-remove-selected': 'deselectItems'
  }
});

export default ManyToManyView;

/**
 * Auto initialize
 */
$('.js-manytomany-ajax-search').each(function() {
  const $this = $(this);

  new ManyToManyView({
    el: this,
    url: $this.data('url'),
  });
});

/**
 * Provides file upload functionality via ajax
 */

const FileUploadView = Backbone.View.extend({
  events: {
    'change': function(e) {
      const $fileInput = $(e.currentTarget);
      const fileData = new window.FormData();
      fileData.append('uploaded_file', $fileInput[0].files[0]);
      fileData.append('upload_to', $fileInput[0].getAttribute('data-upload-to'));

      const $filePreviewSpinner = $fileInput.parent().parent().parent().find('.js-uploaded-file-spinner');
      if ($fileInput.val()) {
        $filePreviewSpinner.show();
        // Disable the input so the input is not validated
        // later re-enable so as to allow validation
        $fileInput.prop('disabled', true);

        $.ajax({
          url: '/file-upload/',
          type: 'POST',
          data: fileData,
          processData: false,
          contentType: false
        }).done((resp) => {
          const filePreview = document.querySelector('.js-uploaded-file');
          if (resp.errors) {
            $filePreviewSpinner.hide();
            const errors = Object.keys(resp.errors).map(name => resp.errors[name].join(', '));
            $fileInput.prop('disabled', false);
            const fileUrl = filePreview.querySelector('a').href.length > 0;
            if (fileUrl) {
              App.components.notificationEventBus.trigger("showNotification", {
                title: 'Something went wrong while uploading this file.',
                status: 'error',
                message: errors
            });
            } else {
              App.utils.handleValidationErrors($fileInput, errors);
              $('.js-file-input').val('');
              $('.js-file-upload-input').val('');
            }
            return;
          }

          $filePreviewSpinner.hide();
          filePreview.querySelector('a').href = resp.url;
          filePreview.querySelector('.js-display-name').innerText = resp.name;
          filePreview.style.display = 'flex';
          filePreview.querySelector('.js-file-upload-input-clear').checked = false;

          const hiddenInputs = $fileInput.siblings('input');
          let hiddenInput = document.createElement('input');
          if (hiddenInputs) {
            hiddenInput = hiddenInputs[0];
          } else {
            $(hiddenInput).insertAfter($fileInput);
          }
          hiddenInput.setAttribute('name', $fileInput.attr('name'));
          hiddenInput.setAttribute('type', 'hidden');
          hiddenInput.value = resp.value;
          $fileInput.prop('disabled', false);
          $(hiddenInput).trigger('change');

          // reset file input to avoid re-submitting file with parent form
          $fileInput.wrap('<form>').closest('form')[0].reset();
          $fileInput.unwrap();
        }).fail(() => {
          $filePreviewSpinner.hide();
          $fileInput.prop('disabled', false);
          App.utils.handleValidationErrors($fileInput, ['Upload Failed']);
        });
      }
    }
  }
});

export default FileUploadView;

/**
 * Auto initialize
 */
$('.js-file-upload-input').each(function() {
  new FileUploadView({
    el: this
  });
});

const inputClear = document.querySelector('.js-file-upload-input-clear');
inputClear && inputClear.addEventListener('click', function (e) {
  const filePreview = e.currentTarget.closest('.js-uploaded-file');
  filePreview.querySelector('a').removeAttribute("href");
  filePreview.style.display = 'none';
  $('.js-file-input').val('').trigger('change');
  $('.js-file-upload-input').val('').trigger('change');
});

const FileUploadForm = Backbone.View.extend({
  events: _.extend({}, Backbone.View.prototype.events, {
    'submit': () => {
      $('.js-file-upload-input').each(function() {
        if (!$(this).parents('.js-keep')) {
          this.remove();
        }
      });
    }
  })
});

$('form').each(function() {
  new FileUploadForm({
    el: this
  });
});

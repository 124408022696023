const getMenuContent = function (url, callback) {
  const csrftoken = Cookies.get('csrftoken'); // eslint-disable-line no-undef

  fetch(url, {
    credentials: 'same-origin',
    method: 'get',
    headers: {
      'X-CSRFToken': csrftoken
    },
  })
    .then(response => response.json())
    .then((content) => {
      callback(content);
    })
}

export default Backbone.View.extend({
  initialize: function (options) {
    this.options = _.extend({}, {
      onShow: function () { },
    }, options);
    this.template = _.template($(this.options.templateSelector).html());
    this.$el.attr("aria-busy", true);
  },
  show: function () {
    if (!this.model.attributes.content && this.options.url && !this.model.attributes.loading) {
      getMenuContent(this.options.url, (content) => {
        this.model.set({ content: content, loading: false });
        this.$el.attr("aria-busy", false);
        this.render();
        this.options.onShow();
      })

      this.model.set({ loading: true });

      this.render();
    }
  },
  render: function () {
    return this.$el.html(this.template(this.model.attributes));
  },
});

import Plyr from 'plyr';

function updateVideoOverlay(headline, subtitle, posterElement) {
  // Handle overlay if no text is on video
  var poster = posterElement[0];
  if (poster === undefined) return;
  if (headline === '' && subtitle === '') {
    poster.classList.add('video__poster-no-overlay');
  } else {
    poster.classList.remove('video__poster-no-overlay');
  }
}

function muteVimeoAutoplay(plyr, plyrOpts) {
  if (plyrOpts.autoplay) {
      plyr.muted = true;
      plyr.volume = 0;
    }
}

function tryPlayVideoAgain(plyr) {
  if (!plyr.playing) {
    plyr.play();
    setTimeout(() => {
      tryPlayVideoAgain(plyr);
    }, 500);
  }
}


const VideoView = Backbone.View.extend({
  currentThumbClass: 'video-thumbnail--current',

  // holds events the users tries to do while plyr is still initializing

  initialize(opts) {
    this.plyrOpts = opts.plyrOpts || {};
    this.numberOfVideos = this.$el.find('.js-change-video').length;

    // extract functional player and visual video component
    this.plyr = new Plyr(this.el.querySelector('.js-player'), this.plyrOpts);
    this.$playButton = this.$el.find('.js-play-video');
    this.$videoPoster = this.$el.find('.js-video-poster'); // bind plyr events
    this.$videoStrip = this.$el.find('.js-video-strip');

    muteVimeoAutoplay(this.plyr, this.plyrOpts);
    setTimeout(() => {
      muteVimeoAutoplay(this.plyr, this.plyrOpts);
      tryPlayVideoAgain(this.plyr);
    }, 500);

    this.plyr.on('pause ended emptied', _.bind(this.setIdle, this));
    this.plyr.on('playing', _.bind(this.updateUIForPlay, this));

    if (this.plyrOpts.isBgVideo) {
      // this was added to allow for looping for youTube header/background videos
      this.plyr.on('ready ended', () => {
        this.$el.addClass('video--isPlaying video--hasPlayed');
      });
    }

    // find headline elements
    this.$mobileHeadline = this.$el.find('.js-mobile-headline');
    this.$mobileSubtitle = this.$el.find('.js-mobile-subtitle');
    this.$headline = this.$el.find('.js-overlay-headline');
    this.$subtitle = this.$el.find('.js-overlay-subtitle');

    // Handle overlay if no text is on video
    updateVideoOverlay(this.$headline.text(), this.$subtitle.text(), this.$videoPoster);
    window.addEventListener('load', () => { this.manipulateVideoPlayerControls(true) });
    this.plyr.elements.container.removeAttribute('tabindex');
    this.plyr.elements.container.setAttribute('tabindex', -1);
    this.plyr.elements.container.setAttribute('aria-hidden', true);
  },

  setIdle() {
    if (this.plyr.embed && this.plyr.provider === 'youtube') {
      const videoIsMuted = this.plyr.embed.isMuted();
      this.plyr.muted = videoIsMuted;
    }
    this.$playButton.prop('disabled', false);
    this.$el.removeClass('video--isPlaying');
  },

  updateVideo(service, id, headline, subtitle, poster) {
    // update the poster
    this.$videoPoster.css('background-image', `url('${poster}')`);
    this.$el.removeClass('video--hasPlayed');
    this.setIdle();

    // Handle overlay if no text is on video
    updateVideoOverlay(headline, subtitle, this.$videoPoster);

    // remove class of previous preview
    const current = this.$el.find('.' + this.currentThumbClass);
    current[0].classList.remove(this.currentThumbClass);

    // add to current preview
    const newCurrent = this.$el.find(`.video-thumbnail[data-video-id="${id}"]`);
    newCurrent[0].classList.add(this.currentThumbClass);

    // update meta
    this.$mobileHeadline.text(headline);
    this.$mobileSubtitle.text(subtitle);
    this.$headline.text(headline);
    this.$subtitle.text(subtitle);
    this.$playButton[0].setAttribute('aria-label', 'Play ' + headline + ' Video');
    // update the player
    this.plyr.source = {
      type: 'video',
      title: headline,
      sources: [{
        src: id,
        provider: service
      }]
    };
  },

  updateUIForPlay() {
    this.$el.addClass('video--isPlaying video--hasPlayed');
    // disable play button to avoid double click and give feedback
    this.$playButton.prop('disabled', true);
    if (this.$playButton.closest('.video__overlay')[0] !== undefined) {
      this.$playButton.closest('.video__overlay')[0].style.display = "none";
    }
    this.plyr.elements.poster.style.display = "none";
    this.manipulateVideoPlayerControls(false);
  },

  manipulateVideoPlayerControls(hide = false) {
    const hideControls = setInterval(() => {
      var videoPlyrContainer = this.plyr.elements.container;
      var videoIFrame = videoPlyrContainer.getElementsByTagName('iframe')[0];
      if (videoIFrame !== undefined) {
        if (hide) {
          videoPlyrContainer.setAttribute('tabindex', '-1');
          videoIFrame.setAttribute('tabindex', '-1');
        } else {
          videoPlyrContainer.setAttribute('tabindex', '0');
          videoIFrame.setAttribute('tabindex', '0');
        }
        videoPlyrContainer.setAttribute('aria-hidden', hide);
        videoIFrame.setAttribute('aria-hidden', hide);
        if (this.$el[0].classList.contains("video--isPlaying")) {
          clearInterval(hideControls);
        }
      }
    }, 100);
  },

  events: {
    'click .js-play-video': function() {
      this.updateUIForPlay();
      this.plyr.muted = false;
      this.plyr.elements.container.focus();
      this.plyr.play();
    },
    'click .js-change-video': function (e) {
      e.preventDefault();

      // Enable overlay for video
      this.$playButton.closest('.video__overlay')[0].style.display = "flex";
      this.plyr.elements.poster.style.display = "block";

      // scroll to the video
      if (this.numberOfVideos > 3) {
        this.$videoStrip.animate({
          scrollLeft: $(e.currentTarget).position().left
        }, 300);
      }

      this.updateVideo(
        e.currentTarget.dataset.service,
        e.currentTarget.dataset.videoId,
        $(e.currentTarget).find('.js-video-info h3').text(),
        $(e.currentTarget).find('.js-video-info div').text(),
        e.currentTarget.dataset.poster
      );
      this.manipulateVideoPlayerControls(true);
      this.plyr.elements.container.setAttribute('tabindex', 0);
    }
  }
});

export default VideoView;

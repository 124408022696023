/**
 * Provides autocomplete functionality by updating suggestions for user input
 * from ajax responses
 *
 * TODO: componetize further, adding the docval select and the X button to this
 *       module, KeywordCompleteFormView?
 */

import Awesomplete from 'awesomplete';

const KeywordCompleteView = Backbone.View.extend({
  initialize (options) {
    this.options = _.extend({}, {
      getDocVal: function() {
        // majority of pages have doc select in navigation, can override at instantiation
        return $('.navigation [name="type"]').val();
      },
      getQueryType: function () {
        // Not implemented, override for instantiation
        return; // eslint-disable-line no-useless-return
      },
      submitOnSelect: true,
      submitOnReturn: true,
      showSearchDropdown: false
    }, options);

    // Handle the fact that we show a list of default links as the first
    // entry in awesomeplete
    if (this.options.showSearchDropdown) {
      const searchContainer = document.querySelector('.search__fields');
      searchContainer.addEventListener('mousedown', (e) => {
        const closestLink = e.target.closest('a');
        if (closestLink) {
          e.preventDefault();
          closestLink.click();
        }
      })
    }

    this.render();
  },

  render () {
    if (!this.awesomplete) {
      this.awesomplete = new Awesomplete(this.el, {
        filter: function() {
          return true; // disable filter, use all of backend results
        },
        minChars: 2
      });
    }

    return this;
  },

  getSuggestions (query) {
    const view = this;
    const data = {
      q: query,
      doc: this.options.getDocVal(),
      queryType: this.options.getQueryType(),
    };

    $.get(this.options.url, data, function(res) {
      const list = res.suggestions;

      view.awesomplete.list = list;
      view.awesomplete.evaluate();
    });
  },

  events: {
    'focus': function(e) {
      const val = e.currentTarget.value;

      if (val && val.length >= this.awesomplete.minChars) {
        this.getSuggestions(val);
      }
      else if (this.options.showSearchDropdown) {
        const $searchDropdown = $('.search__dropdown');
        $searchDropdown.addClass('active');
      }
    },

    'blur': function() {
      if (this.awesomplete) {
        if (this.options.showSearchDropdown) {
          const $searchDropdown = $('.search__dropdown');
          $searchDropdown.removeClass('active');
        }
        this.awesomplete.list = [];
      }
    },

    'input': _.debounce(function(e) {
      const val = e.currentTarget.value;

      if (val && val.length >= this.awesomplete.minChars) {
        this.getSuggestions(val);
      } else {
        this.awesomplete.list = [];

        if (this.options.showSearchDropdown) {
          const $searchDropdown = $('.search__dropdown');
          $searchDropdown.addClass('active');
        }
      }
    }, 300),

    'keydown': function(e) {
      if (e.keyCode === 9) { // TAB
        // copy of 'blur' logic
        // for keyboard we need to hide dropdown ASAP
        // otherwise focus on TAB is moved to that dropdown
        // and when it is hidden on blur, browsers are confused
        // (e.g. Safari starts tabbing from the begining of the page)
        if (this.awesomplete) {
          if (this.options.showSearchDropdown) {
            const $searchDropdown = $('.search__dropdown');
            $searchDropdown.removeClass('active');
          }
          this.awesomplete.list = [];
        }
      }

      // Prevent form submission when hitting return
      // and and having awesomeplete be open and have nothing selected
      if (e.keyCode === 13 && !this.options.submitOnReturn
          && this.awesomplete.opened && !this.awesomplete.selected) {
            e.preventDefault();
      }
    },

    'awesomplete-selectcomplete': function() {
      this.awesomplete.close();

      if (this.options.submitOnSelect) {
        this.$el.closest('form').submit();
      }
    },
    'awesomplete-open': function() {
      if (this.options.showSearchDropdown) {
          const $searchDropdown = $('.search__dropdown');
          $searchDropdown.removeClass('active');
      }
    }
  }
});

export default KeywordCompleteView;

/**
 * Auto initialize
 */
$('#offcanvas-search-form input[type="search"]').each(function () {
  const $this = $(this);

  new KeywordCompleteView({
    el: this,
    url: $this.closest('form').data('ajax-url'),
    getDocVal: function () {
      return 'all';
    }
  });
});

$('#nav-search-form input[type="search"]').each(function () {
  const $this = $(this);

  new KeywordCompleteView({
    el: this,
    url: $this.closest('form').data('ajax-url'),
    showSearchDropdown: true,
  });
});

$('#search-form input[type="search"]').each(function () {
  const $this = $(this);

  new KeywordCompleteView({
    el: this,
    url: $this.closest('form').data('ajax-url'),
    submitOnSelect: false,
    getDocVal: function () {
      return $this.closest('form').data('doc-val');
    }
  });
});

$('.js-previous-service input[type="text"]').each(function () {
  const $this = $(this);

  new KeywordCompleteView({
    el: this,
    url: $this.closest('.js-previous-service').data('ajax-url'),
    submitOnSelect: false,
    submitOnReturn: false
  });
});

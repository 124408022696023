/**
 * Ajax in views
 */
import AjaxView from './AjaxView';

const RemoveMultipleMemberView = AjaxView.extend({
  render: function() {
    const view = this;
    const url = this.model.get('templateUrl');
    const data = this.model.get('params') || '';

    $.post(url, data)
      .done(_.bind(this.lazyRender, view))
      .fail(function() {
        view.$el.html('<ul class="errorlist"><li>failed to POST ' + url + '</li></ul>');
      });

    return this;
  },
  events: {
    'submit #remove-confirmation-membership-form': 'ajaxSubmitForm',
  }
});

export default RemoveMultipleMemberView;

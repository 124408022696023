import 'jquery.mmenu';
import 'jquery-focuspoint';
import moment from 'moment';
import Clipboard from 'clipboard';
import flatpickr from 'flatpickr';
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";
import WebFont from 'webfontloader';
import isMobile from 'ismobilejs';
import components from './components';
import * as utils from './utils';
// import MonthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index";
import MonthSelectPlugin from "./monthSelectPlugin";

const App = Backbone.View.extend({
  initialize () {
    Backbone.View.prototype.initialize.call(this);

    this._initFontLoader();
    this._initClipboardCopy();
    this._initSearchInputs();
    this._initDiscoverySearchInputs();
    document.querySelector('nav.navigation') && this._initDesktopNav();
    this._initUserDropdown();
    this._initFlatpickr();
    this._initFocuspoint();
    this._initLazyVideos();
    this._initExternalLinks();
    this._initTooltips();
    this.ignoreTabindexInHiddenElements();

    $(window).on('load', () => {
      this._checkShowIntercomPopup();
    });

    // TODO: These are very similar and could be merged or turned into a component
    this._initGradientOverflow();
    this._initDetectTableOverflow();
    this._initDetectPaneOverflow();
    this._initTableSortHeader();
  },

  ignoreTabindexInHiddenElements () {
    Array.from(document.getElementsByClassName('js-ignore-tab'))
    .forEach((element) => {
      Array.from(element.getElementsByTagName('*')).forEach((descendant) => {
        descendant.tabIndex = -1;
      });
    });
  },

  _initFontLoader () {
    WebFont.load({
      custom: {
        families: [
          'Avenir Next W01:n4,i4,n5,n6,n7', // fonts.com fonts
          'LastParadise:n4' // purchased webfont https://www.youworkforthem.com/font/T5811/last-paradise/buying/
        ]
      }
    });
  },

  /**
   * Displays the Intercom popup if query params contain 'support=true'
   */
  _checkShowIntercomPopup () {
    // http://www.timetler.com/2013/11/14/location-search-split-one-liner/
    const query = window.location.search.slice(1).split('&');
    const params = _.object(_.compact(_.map(query, function (item) {
      if (item) {
        return item.split('=').map(i => i.toLowerCase());
      }
      return undefined;
    })));

    if (params.support === 'true') {
      if (_.isFunction(window.Intercom)) {
        window.Intercom('showNewMessage');
      }
    }
  },

  /**
   * Clipboard.JS - copy to clipboard
   */
  _initClipboardCopy () {
    const clipboard = new Clipboard('.js-clipboard');

    clipboard.on('success', (e) => {
      this.$('#js-clipboard').addClass('validation--success');
      this.$('#js-clipboard-success').removeClass('invisible');
      this.$('#js-clipboard-success').focus();
      e.clearSelection();
    });

    clipboard.on('error', () => {
      this.$('#js-clipboard').addClass('validation--error');
      this.$('#js-clipboard-error').removeClass('invisible');
      this.$('#js-clipboard-error').focus();
    });
  },

  /**
   * Search inputs clearing
   */
  _initSearchInputs () {
    const searchClears = document.getElementsByClassName('search__clear');
    [].forEach.call(searchClears, (searchClear) => {
      const searchInput = [].find.call(
        searchClear.parentElement.getElementsByTagName('input'), e => e.type === 'search');

      if (searchInput.value) {
        searchClear.classList.remove('hidden-for-js');
        searchClear.setAttribute('aria-hidden', false);
      }

      searchInput.addEventListener('input', () => {
        if (searchInput.value.length > 0) {
          searchClear.classList.remove('hidden-for-js');
          searchClear.setAttribute('aria-hidden', false);
        } else {
          searchClear.classList.add('hidden-for-js');
          searchClear.setAttribute('aria-hidden', true);
        }
      });

      ['touchstart', 'click'].forEach((name) => {
        searchClear.addEventListener(name, (e) => {
          e.preventDefault();
          searchClear.classList.add('hidden-for-js');
          searchInput.value = '';
        });
      });
    });

    $('.js-button-for-radio').on('click', function(e) {
      e.preventDefault();
      e.stopPropagation()
      $('.js-button-for-radio').attr('aria-pressed', false).attr('disabled', false);
      const button = $(e.target);
      const radio = $('#' + button.attr('data-for'));
      const newValue = radio.attr('value');
      $('[name="units"]').val([newValue])
      radio.trigger('change');
      button.attr('aria-pressed', true).attr('disabled', true);
    });
  },

  /**
   * Discover Opportunities from HeaderComponent
   */
  _initDiscoverySearchInputs () {
    $('.js-show-discovery, .js-dismiss-discovery').on('click', (e) => {
      e.preventDefault();
      $(e.currentTarget).closest('form').find('.discovery-bar').toggleClass('discovery-bar--active');
    });
  },

  /**
   * Navigation drawer behavior
   */
  _initDesktopNav() {
    /* Constants */
    const navDrawerActiveClass = 'navigation__drawer--active';
    const navDropdownActiveClass = 'navigation__dropdown--active';

    /* DOM Elements */
    const serveNav = [...document.querySelectorAll('[data-content-type="serve"]')];
    const hostNav = [...document.querySelectorAll('[data-content-type="host"]')];
    const moreNav = () => [...document.querySelectorAll('[data-content-type="more"]')];
    const allDropdowns = [...document.querySelectorAll('.navigation__dropdown')];
    const navDrawerContainer = document.querySelector('.navigation__drawer');
    const navDrawerLeftColumn = document.querySelector('#drawer-left-column');
    const navDrawerInnerContent = document.querySelector('.nav-drawer__inner');
    const mobileDrawerCloseButton = document.querySelector('.nav-drawer-close');
    const searchNav = document.querySelector('[data-drawer="search"]');
    const mobileOverlay = document.querySelector('#mobile-overlay');
    const bodyEl = document.querySelector('body');
    const htmlEl = document.querySelector('html');

    /* Utilities */
    // CSS
    const ifNavDrawerOpen = () => (
      (navDrawerContainer) && (navDrawerContainer.classList.contains(navDrawerActiveClass)));

    const addActiveClassIfSearchPage = () => (
      (window.SY) && (window.SY.onSearchPage === 'search') && (
        searchNav.classList.add(navDropdownActiveClass)));

    const setVisibilityOnClick = (navElsForClicked, navElsOther) => {
      navElsForClicked.forEach((el) => { el.classList.remove('hide-for-js'); });
      navElsOther.forEach((el) => { el.classList.add('hide-for-js'); });
      !ifNavDrawerOpen() && addActiveClassIfSearchPage();
    };

    const toggleDropdownArrowSVGs = (target) => {
      const hasActiveClass = el => el.classList.contains(navDropdownActiveClass);
      const toggleActiveClass = el => el.classList.toggle(navDropdownActiveClass);
      const notTarget = el => el !== target;

      if (!target) {
        // clicked outside nav
        allDropdowns.filter(hasActiveClass).forEach(toggleActiveClass);
      } else {
        // remove from any existing nav item
        allDropdowns.filter(hasActiveClass).filter(notTarget).forEach(toggleActiveClass);
        // ...then apply to target nav item
        target.classList.toggle(navDropdownActiveClass);
      }
    };

    const hasMatchingParentBySelector = (target, selector) => (
      [...document.querySelectorAll(selector)].some(el => el !== target && el.contains(target)));

    const toggleNavDrawer = target => (
      (!target /* clicked outside nav */
       || target.classList.contains(navDropdownActiveClass) /* clicked on already active */
       || !ifNavDrawerOpen() /* clicked on nav item, not active */) && (
         navDrawerContainer && navDrawerContainer.classList.toggle(navDrawerActiveClass)));

    // DOM
    const isTabletPortrait = () => (
      isMobile.tablet && window.matchMedia("(orientation: portrait)").matches);
    const getTarget = target => (
      hasMatchingParentBySelector(target, '.navigation__link') ?
        target.closest('[data-drawer]')
        : target);

    const decodeHTML = (data) => {
      const element = document.createElement('textarea');
      element.innerHTML = data;
      return element.value;
    };

    // General
    const parse = data => JSON.parse(data);

    const getHostName = (url) => {
      const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
        return match[2];
      }
      return null;
    };

    const isExternalLink = url => getHostName(url) !== window.location.hostname;

    /* Pipelines */
    const generateMoreMenu = (data) => {
      const menuDataAsJSON = decodeHTML(data.menuData);

      // NOTE: These magic numbers in the calculation of the tabindex correspond to the
      //       starting values from the navigation.html template, with a plus one
      //       to account for 0-indexed values
      const linkDesktopHTML = (item, idx) => (
        `<li class="navigation-list-item">
           <a href="${item.url}" class="link--primary" ${isExternalLink(item.url) ? 'target="_blank"' : ''} tabindex="${17 + idx + 1}">${item.name}
           ${isExternalLink(item.url) ?
              `<svg class="svg-icon svg-icon--blue mlh" role="presentation" >
                 <use xlink:href="/static/svg/sprite/symbol/sprite.svg#icon--external"></use>
              </svg></a>`
              : ''
            }
         </li>`);

      const moreMenuDesktopHTML = (sectionTitle, expanded, sectionItems, idx) => (
        `<div class="large-3 columns nav-item--more" data-content-type="more">
           <p class="heading--micro">${sectionTitle}</p><ul class="navigation-list">${sectionItems.map(item => linkDesktopHTML(item, idx)).join('')}</ul>
         </div>`);

      const linkMobileHTML = item => (
        `<li><a href="${item.url}" class="link--primary">${item.name}</a></li>`);

      const moreMenuMobileHTML = (sectionTitle, expanded, sectionItems) => (
        `<div class="expander nav-expander js-expander ${expanded ? 'expander__info--active' : ''}" data-content-type="more">
             <a href="#" class="expander__toggle js-expander__toggle" role="button">
               <span class="heading--micro">${sectionTitle}</span>
               <svg class="expander__indicator svg-icon svg-icon--deep-medium-navy svg-icon--extra-large" role="presentation" ><use xlink:href="/static/svg/sprite/symbol/sprite.svg#icon--angle-down"></use></svg>
             </a>
             <ul class="expander__info js-expander__info">
                ${sectionItems.map(linkMobileHTML).join('')}
             </ul>
           </div>`);

      const getSectionsHTML = (fn, sections) => (
        sections.map((section, idx) => {
          const { name: sectionTitle, expanded, items: sectionItems } = section;
          return fn(sectionTitle, expanded, sectionItems, idx);
        }));

      if (isMobile.phone || isTabletPortrait()) {
        getSectionsHTML(moreMenuMobileHTML, parse(menuDataAsJSON).slice(0, 4)).forEach(
          section => navDrawerInnerContent.insertAdjacentHTML('beforeend', section));
      } else {
        getSectionsHTML(moreMenuDesktopHTML, parse(menuDataAsJSON)).forEach(
          section => navDrawerInnerContent.insertAdjacentHTML('beforeend', section));
      }
    };

    const toggleMobileOverlay = () => {
      if (mobileOverlay) {
        mobileOverlay.classList.toggle('hide-for-js');
      }

      if (!bodyEl.style.overflowY || bodyEl.style.overflowY === 'visible') {
        bodyEl.style.overflowY = 'hidden';
        htmlEl.style.overflowY = 'hidden';
      } else {
        bodyEl.style.overflowY = 'visible';
        htmlEl.style.overflowY = 'visible';
      }
    };

    const clickedOutsideOfOpenNav = target => (
      (!allDropdowns.filter(dropdown => dropdown === target).length &&
       !target.closest('#drawer-unified') &&
       ifNavDrawerOpen()));

    const navCloseSequence = () => (
      // eslint-disable-next-line no-sequences
      toggleNavDrawer(),
      toggleDropdownArrowSVGs(),
      addActiveClassIfSearchPage(),
      (isMobile.phone || isTabletPortrait()) && toggleMobileOverlay()
    );

    /* Main */
    document.addEventListener('DOMContentLoaded', () => {
      if (mobileOverlay) {
        mobileOverlay.classList.add('mobile-overlay');
      }

      allDropdowns.forEach((dropdown) => {
        const events = ['click', 'keydown'];

        events.forEach((eventType) => {
          dropdown.addEventListener(eventType, (e) => {
            const keyPressed = e.keyCode || e.which;
            const target = getTarget(e.target);
            const typeOfButtonClicked = target.dataset.drawer;
            const ifMoreMenuAlreadyRendered = [...document.querySelectorAll('[data-content-type="more"]')].length;

            if (keyPressed !== 9 && eventType === 'keydown') {
              // ...user pressed <TAB>
              return undefined;
            }

            if (!(ifNavDrawerOpen() && !target.classList.contains(navDropdownActiveClass))) {
              (isMobile.phone || isTabletPortrait()) && toggleMobileOverlay();
            }

            toggleNavDrawer(target);
            toggleDropdownArrowSVGs(target);

            switch (typeOfButtonClicked) {
              case 'serve':
                navDrawerLeftColumn.classList.remove('hide-for-js');
                setVisibilityOnClick(serveNav, [...hostNav, ...moreNav()]);
                break;
              case 'host':
                navDrawerLeftColumn.classList.remove('hide-for-js');
                setVisibilityOnClick(hostNav, [...serveNav, ...moreNav()]);
                break;
              case 'more':
                navDrawerLeftColumn.classList.add('hide-for-js');
                ((!ifMoreMenuAlreadyRendered && (window.SY.menuData !== '[]')) && generateMoreMenu(window.SY));
                setVisibilityOnClick(moreNav(), [...serveNav, ...hostNav]);
                break;
              case 'search':
                toggleNavDrawer();
                window.location.href = window.SY.searchUrl;
                break;
              default:
                // noop
              }
            return undefined;
          });
        });
      });

      document.addEventListener('click', e => (
        clickedOutsideOfOpenNav(getTarget(e.target)) && navCloseSequence()));

      document.addEventListener('keydown', (e) => {
        if (e.keyCode == 9) {
          clickedOutsideOfOpenNav(getTarget(e.target)) && navCloseSequence()
        }
      })

      if (mobileDrawerCloseButton) {
        mobileDrawerCloseButton.addEventListener('click', () => navCloseSequence());
      }

      addActiveClassIfSearchPage();
    });
  },

  _initUserDropdown() {
    const openUserDropdown = () => {
      // get the open nav drawers; should either be 0 or 1
      const openDropdown = this.$('#user-dropdown.open');

      if (openDropdown.length > 0) {
        return openDropdown[0].id;
      }

      return undefined;
    };

    const clickInside = (target) => {
      const container = $('#user-dropdown')[0];
      return $.contains(container, target);
    };

    const closeDropdown = () => {
      $('#user-dropdown').removeClass('open');
      $('[data-dropdown=user-dropdown]').removeClass('open');
    };

    this.$el.on('click', (e) => {
      const target = e.target;
      const currentOpen = openUserDropdown();

      if ($(target).parents('[data-dropdown]').length > 0) {
        return;
      }

      if (currentOpen && !clickInside(target)) {
        // Close it somehow
        closeDropdown();
      }
    });
  },

  _initFocuspoint() {
    $('.focuspoint').focusPoint();
  },

  /**
   * Initialize the Flatpickr for all date inputs
   */
  _initFlatpickr(options = {}, selector = '.js-date-input:not(".flatpickr-input"):not(.input)') {
    if (options.plugins) options.plugins = [labelPlugin(), ...options.plugins];

    $(selector).each(function () {
      let flatpickrConfig = _.extend({}, {
        altInput: true, // human friendly date formatting
        altFormat: 'F j, Y',
        dateFormat: 'Y-m-d',
        appendTo: document.getElementById('flatpickr_container') || undefined,
        plugins: [labelPlugin()]
      }, options);

      if (this.classList.contains('js-monthly')) {
        let minDate = new Date(this.getAttribute('data-minDate'));
        minDate = new Date(minDate.getUTCFullYear(), minDate.getUTCMonth(), 1);
        let maxDate = new Date(this.getAttribute('data-maxDate'));
        maxDate = new Date(maxDate.getUTCFullYear(), maxDate.getUTCMonth(), maxDate.getUTCDate());
        flatpickrConfig = _.extend({}, flatpickrConfig,
          {
            plugins: [labelPlugin(), new MonthSelectPlugin({dateFormat: "Y-m"})],
            minDate: minDate,
            maxDate: maxDate,
            disableMobile: true,
          });
      }

      if (this.classList.contains('js-birthday')) {
        const maxDate = moment().subtract(16, 'years').toDate();
        flatpickrConfig.maxDate = maxDate;
      }
      var flatpickrInput = (() => flatpickr(this, flatpickrConfig))();
      // Copy aria required value to flatpickr altInput to keep consistency.
      if (this.hasAttributes('aria-required') && flatpickrInput.altInput) {
        const originValue = this.getAttribute('aria-required');
        flatpickrInput.altInput.setAttribute('aria-required', originValue);
      }
    });

    // Handles blur event for calendar in modals as modals have tabloop functionality.
    $('body').on('blur', '.flatpickr-calendar.open', function (e) {
      var $gainsFocus = $(e.relatedTarget);
      var $loseFocus = $(e.target);
      if (document.body.classList.contains('user-is-tabbing') && $loseFocus.parents('.flatpickr-calendar.open').length > 0 && ($gainsFocus.length === 0 || $gainsFocus.parents('.flatpickr-calendar').length === 0) && $gainsFocus.parents('.bbm-modal').length === 0) {
        var $dateInput = $('.js-date-input.active');
        $dateInput.focus();
      }
    });

    // hack for flatpickr to support space bar support for calendar
    $('body').on('keydown', '.flatpickr-calendar.open, .js-date-input', function (e) {
      if (e.keyCode === 32) {
        e.preventDefault();
        e.stopPropagation();
        $(e.target).click();
      }
    });
  },

  _initTableSortHeader () {
    $('body').on('keydown', '.js-sortable-headers', function (e) {
      const url = e.target.getAttribute('data-url')
      if ((e.keyCode === 32 || e.keyCode === 13) && url) {
        e.preventDefault();
        e.stopPropagation();
        window.location.href = url;
      }
    });
    $('button.js-sortable-headers').on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      const url = this.getAttribute('data-url')
      if (url) window.location.href = url;
    });
  },

  /**
   * Table Gradient When Scrolling
   */
  _initGradientOverflow () {
    this.$('.table-scroll-x').scroll(_.throttle(() => {
      const windowWidth = this.$('.table-scroll-x').width();
      const documentWidth = this.$('.table').width();
      const pos = $('.table-scroll-x').scrollLeft() + windowWidth;

      if (pos === documentWidth) {
        // Right edge
        $('.js-table-overflow').removeClass('table-container--gradient-overflow');
      } else {
        // In between
        $('.js-table-overflow').addClass('table-container--gradient-overflow');
      }
    }, 50));
  },

  /**
   * Table Gradient Overflow
   */
  _initDetectTableOverflow () {
    const detect = (el) => {
      const $parent = $(el);
      const $child = $parent.find('.table');

      if ($child[0].scrollWidth > $parent[0].clientWidth) {
        $parent.addClass('table-container--gradient-overflow');
      } else {
        $parent.removeClass('table-container--gradient-overflow');
      }
    };

    this.$('.js-table-overflow').each((index, el) => {
      // detect immediately to see if shadow is necessary
      detect(el);

      // do it again on widow resize
      $(window).on('resize', _.throttle(_.bind(detect, this, el), 100));
    });
  },

  _initDetectPaneOverflow () {
    const detect = (el) => {
      const $parent = $(el);
      const $child = $parent.find('.pane');

      if ($child[0].scrollHeight > $parent[0].clientHeight) {
        $parent.addClass('pane-container--gradient-overflow');
      } else {
        $parent.removeClass('pane-container--gradient-overflow');
      }
    };

    this.$('.js-pane-overflow').each((index, el) => {
      // detect immediately to see if shadow is necessary
      detect(el);

      // do it again on widow resize
      $(window).on('resize', _.throttle(_.bind(detect, this, el), 100));
    });
  },

  _initLazyVideos () {

    $('video.lazyload').each(function() {
      const $this = $(this);

      $this.children('source, track').each(function(index, el) {
        const $el = $(el);
        const src = $el.attr('data-src');
        if (src) {
          $el.attr('src', src);
          $this.load(src);
        }
      });
    });
  },

  _initExternalLinks () {
    utils.addTargetAttributeToExternalLinks('.cms a');
    utils.addTargetAttributeToExternalLinks('.task-card a');
  },

  _initTooltips () {
    document.addEventListener('keydown', function(e) {
      if (e.key === 'Escape') {
        const targets = document.getElementsByClassName(
            'icon-tooltip__container'
        );
        if (targets) {
          Array.from(targets).forEach((tooltip) => {
            tooltip.style.visibility = 'hidden';
            tooltip.style.display = 'none';
            // enable tooltip again
            tooltip.parentElement.onmouseleave = (ev) => {
              ev.target.onmouseleave = undefined;
              ev.target.onmouseenter = undefined;
              tooltip.style.visibility = null;
              tooltip.style.display = null;
            };
            tooltip.parentElement.onmouseenter = (ev) => {
              ev.target.onmouseleave = undefined;
              ev.target.onmouseenter = undefined;
              tooltip.style.visibility = null;
              tooltip.style.display = null;
            };
          });
        }
      }
    }, true);

    Array.from(document.querySelectorAll('.icon-tooltip:not(.icon-tooltip__styleguide)')).forEach(tooltip => tooltip.addEventListener('click', function(e) {
      utils.repositionToolTip(e);
    }));
  },

  _handleExpander(e) {
    e.preventDefault();
    e.stopPropagation();

    const $container = $(e.currentTarget).closest('.js-expander');
    $container.toggleClass('js-remove-expander')
    $container.toggleClass('expander__info--active');

    $container.find(".js-expander__info").attr('aria-hidden', !$container.hasClass('expander__info--active'));

    // Change text with toggle (view/hide)
    const textEl = $container[0] ? $container[0].querySelector('.expander-text') : null;

    if (textEl) {
      const text = (textEl.dataset.textSwap == textEl.textContent
                    ? textEl.dataset.textOriginal : textEl.dataset.textSwap);
      textEl.textContent = text;
    }

    const srHelper = e.currentTarget.getElementsByClassName('js-expander-sr');
    if (srHelper && srHelper.length > 0) {
      srHelper[0].innerText = $container.hasClass('expander__info--active') ? 'Expanded' : 'Collapsed';
    }
  },

  events: {
    /**
     * Shows browser print dialog
     */
    'click .js-print': function (e) {
      e.preventDefault();
      window.print();
    },

    /**
     * Hides and removes notifications from Django's messages
     */
    'click .js-dismiss-notification': function(e) {
      e.preventDefault();

      const $notification = $(e.currentTarget).closest('.notification');
      $notification.slideUp(function () {
        $notification.remove();
      });
    },

    /**
     * Prevents multiple form submits, disables the button clicked on the form,
     * appends a hidden input to the form with that button's name+value so the data
     * is still sent to the server
     */
    'click button[type="submit"]': function (e) {
      const $button = $(e.currentTarget);
      const $form = $button.closest('form');

      if ($form.length) {
        $form.one('submit', () => {
          $form.find('button[type="submit"]').prop('disabled', true);

          if ($button.attr('name')) {
            $(`<input type="hidden" name="${$button.attr('name')}" value="${$button.val()}">`).appendTo($form);
          }
        });
      }
    },

    /**
     * Expander
     */
    'click .js-expander__toggle': function (e) {
      this._handleExpander(e);
    },

    'keydown .js-expander__toggle': function (e) {
      if (e.keyCode == 13 || e.keyCode == 32) {
        this._handleExpander(e);
      }
    },

    // Expand All
    'click .js-expand': function (e) {
      e.preventDefault();
      e.stopPropagation();

      $('.expander').addClass('expander__info--active');
    },
    // Collapse All
    'click .js-collapse': function (e) {
      e.preventDefault();
      e.stopPropagation();

      $('.expander').removeClass('expander__info--active');
    },

    'click .navigation__dropdown': function (e) {
      e.preventDefault();
    },

    'click .js-date-input:not(".flatpickr-input"):not(.input)': function(e) {
      this._initFlatpickr();
      e.currentTarget.flatpickr.open();
      console.log('open?');
    },

    'keydown .js-click': function(e) {
      if (e.keyCode == 13 || e.keyCode == 32) {
        e.preventDefault();
        $(e.target).click() // pure JS click doesn't work for SVG
      }
    },

    'keydown .js-dropdown': function(e) {
      if (e.keyCode == 13 || e.keyCode == 32) {
        e.preventDefault();
        var target = e.target.getAttribute('data-toggle');
        if (target) {
          $(document.getElementById(target)).foundation('toggle');
        }
      }
    }
  }
}, {
  components,
  utils
});

export default App;

global.App = App;

// explicitly expose functions from within App to the templates
global.App.initFlatpickr = App.prototype._initFlatpickr;

/**
 * Message Thread View
 *
 * Allows a user to send a message in a thread. Users are scrolled to the bottom
 * of the message thread, the window is anchored to the bottom of the pane footer,
 * and the send button is disabled by default.
 *
 * The message is sent over AJAX with 3 statuses: 'Sending...', 'Sent', and 'Failed'.
 */

const MessageThreadView = Backbone.View.extend({
  initialize () {
    this.sendButton = this.el.querySelector('.js-message-btn-reply');
    if (this.sendButton) {
      this.sendButton.disabled = true;
    }
    this.emptyState = document.querySelector('.message__empty-state');
    const self = this;

    window.onload = function() {
      self.scrollToBottom();
      self.anchorWindow();
    };
  },

  messageInput (e) {
    this.message = e.currentTarget.value;
    this.sendButton.disabled = !(this.message && this.message !== '');
  },

  resetInputs () {
      document.querySelector('#id_text').value = '';
      this.sendButton.disabled = true;
  },

  scrollToBottom () {
    const threadEl = document.querySelector('.message__thread-wrapper');
    threadEl.scrollTop = threadEl.scrollHeight - threadEl.clientHeight;
  },

  anchorWindow () {
    const windowHeight = window.innerHeight;
    const $threadPane = $('.message__thread-pane');
    const threadBottom = $threadPane.offset().top + $threadPane.outerHeight(true)

    var scrollPosition = threadBottom - windowHeight
    const $footer = $('.pane__footer--action');
    if ($footer) {
      const footerHeight = $footer.outerHeight(true);
      scrollPosition += footerHeight
    }
    setTimeout(function() {
      window.scrollTo(0, scrollPosition);
    }, 0);
  },

  updateURL (url) {
    const stateObj = {};
    window.history.pushState(stateObj, '', url);
  },

  sendReply (e) {
      e.preventDefault();

      // Remove the empty state if it exists
      if (this.emptyState) {
        this.emptyState.style.display = 'none';
      }

      const $form = $(e.currentTarget).closest('form');
      const url = $form[0].action;
      const self = this;

      const markup = `
        <div class="message__thread">
          <div class="message right">
            <p class="message__text message__text--sending">
              ${this.message}
            </p>
            <p class="message__status is-sending">Sending...</p>
          </div>
        </div>
      `;

      // Append the new message to the thread
      const thread = document.createElement('div');
      thread.innerHTML = markup;
      const threadBody = thread.firstElementChild;

      const messageThread = document.querySelector('.message__thread-wrapper');
      messageThread.appendChild(threadBody);

      // Scroll to bottom
      this.scrollToBottom();

      // Set status on post success or failure
      $.post(url, $form.serialize())
        .done(function (resp) {
          if (window.SY && SY.messageEditedModal) {
            SY.messageEditedModal.inputDataChanged = false;
          }
          // If sending first message, update url with ID
          if (self.emptyState) {
            self.updateURL(resp.thread_url);
            self.emptyState = false;
          }

          // Update message text styles
          const $lastMessage = $('.message__text--sending').last();
          $lastMessage.removeClass('message__text--sending');
          $lastMessage.addClass('message__text--sent');

          // Update the status text
          const $statusText = $('.is-sending').last();
          $statusText.removeClass('is-sending');
          $statusText.addClass('is-success');
          $statusText.text('Sent');

          // Clear input and disabled button
          self.resetInputs();
        })
        .fail(function () {
          // Update the status text
          const $statusText = $('.is-sending').last();
          $statusText.addClass('is-failure');
          $statusText.text('Message failed. Try again.');
        });
  },

  events: {
    'input #id_text': 'messageInput',
    'click .js-message-btn-reply': 'sendReply'
  }
});

export default MessageThreadView;


/**
 * Auto initialize
 */
Array.from(document.querySelectorAll('.js-message-thread')).forEach((el) => {
  new MessageThreadView({
    el
  });
});

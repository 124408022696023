/**
 * Narrowly focused function to bring active Sub Nav tabs into the user's viewport
 * where needed. Only handles for X axis anchoring as the Sub Nav is naturally in view
 * when interacted with so TabNav interactions would have been overkill (X and Y).
 *
 * Could be quickly abstracted to be used more globally if there is ever a need.
 */

const AnchorSubNavView = Backbone.View.extend({
  initialize () {
    this.render();
  },

  // Helper function to determine if the active nav item is completely in horizontal view
  getHorizontalOffset (el) {
    const rect = el.getBoundingClientRect();
    const viewportEdge = (window.innerWidth || document.documentElement.clientWidth)

    if (rect.right >= viewportEdge) {
      // Subtract an additional 15, to not have the resulting edge be flush where possible
      const offsetDist = (rect.right - viewportEdge) + 15;
      return offsetDist;
    }

    return (0);
  },

  render () {
    const tabContainer = document.querySelector('.sub-navigation');
    const activeTab = document.querySelector('.sub-navigation__link--active');

    if (tabContainer && activeTab) {
      const offsetDist = this.getHorizontalOffset(activeTab)
      if (offsetDist > 0) {
        tabContainer.scrollLeft = offsetDist;
      }
    }
  },
});

export default AnchorSubNavView;

/**
 * Auto initialize
 */
document.addEventListener('DOMContentLoaded', function() {
  new AnchorSubNavView({
    el: document.body
  })
});

/**
 * Ajax in views
 */

import { getStatic } from '../utils';

const AjaxView = Backbone.View.extend({
  /** Start with a loading indicator */
  el: `<div><div class="text-center"><img class="m2 indicator" src="${getStatic('images/loading.gif')}" alt="loading"></div></div>`,

  /**
   * Renders initial loading indicator then gets template html from server and
   * replaces view.
   */
  render () {
    const view = this;
    const url = this.model.get('templateUrl');
    const data = this.model.get('params') || '';

    $.get(url, data)
      .done(_.bind(this.lazyRender, view))
      .fail(function() {
        view.$el.html(`<ul class="errorlist"><li>failed to GET ${url}</li></ul>`);
      });

    return this;
  },

  /**
   * Callback for initialization after template rendered from server.
   *
   * @param {String} html template html
   */
  lazyRender (html) {
    const $html = $(html).hide();
    this.$el.html($html);
    $html.slideDown('fast');

    if (_.isFunction(this.lazyInitialize)) {
      this.lazyInitialize();
    }

    this.delegateEvents();
  },

  /**
   * Generic form submission for displaying errors and redirecting success.
   *
   * @param {Object} e jQuery event
   */
  ajaxSubmitForm (e) {
    e.preventDefault();
    const view = this;
    const $form = $(e.currentTarget).closest('form');
    const $submitButton = $form.find('[type="submit"]');
    const url = $form[0].action;
    const data = $form.find(':input').filter(function(idx, el) {
      const $el = $(el);
      return $el.not('[name="csrfmiddlewaretoken"]').length;
    }).serialize();

    $submitButton.prop('disabled', true); // disable submit button during ajax post

   return $.post(url, data)
      .done(function(response) {
        if (!response.redirect) {
          const html = response.html ? response.html : response;
          view.$el.html(html);

          if (_.isFunction(view.lazyInitialize)) {
            view.lazyInitialize();
          }
        } else {
          window.location = response.redirect;
        }
      })
      .fail(function() {
        $submitButton.prop('disabled', false); // reenable submit button
        view.$el.html(`<ul class="errorlist"><li>failed to POST ${url}</li></ul>`);
      });
  }
});

export default AjaxView;

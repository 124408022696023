// =============================================================================
// Imports
// =============================================================================

import { getParents } from '../utils/index';


// =============================================================================
// Utilities
// =============================================================================

const getOffsetFromWindowName = regex => JSON.parse((window.name).match(regex));

const dictValueMatch = '[0-9]+.?([0-9]+)?';

const eitherXY = new RegExp(`{"(xOffset|yOffset)":${dictValueMatch}}`, 'gi');

const createOffsetRegex = offsetType => (
  new RegExp(`{"${offsetType}":${dictValueMatch}}`, 'gi'));

const xOffsetRegex = createOffsetRegex('xOffset');
const yOffsetRegex = createOffsetRegex('yOffset');


// =============================================================================
// View Component
// =============================================================================

const AnchorWindowView = Backbone.View.extend({
  initialize () {
    this.STORAGE_AVAILABLE = true;

    if (!this.localStorageIsAvailable()) {
      this.STORAGE_AVAILABLE = false;
    }

    this.render();
  },

  render () {
    const xyOffsetsInLocalStorage = (
      this.STORAGE_AVAILABLE &&
        ((window.localStorage.getItem('yOffset') ||
          window.localStorage.getItem('xOffset'))));
    const xyOffsetsInWindowName = (window.name).match(eitherXY);

    if (xyOffsetsInLocalStorage || xyOffsetsInWindowName) {
      this.positionWindow();
    }
  },

  localStorageIsAvailable () {
    try {
      window.localStorage.test = 'will fail if localStorage is unavailale';
    } catch (e) {
      return false;
    }
    return true;
  },

  getYOffset(el) {
    const elTop = document.getElementById(el).getBoundingClientRect().top;
    const topOffset = elTop + window.pageYOffset;
    return topOffset;
  },

  positionWindow () {
    const tabNav = document.querySelector('.tab-nav');
    let x = 0;
    let y = 0;

    if (!(this.STORAGE_AVAILABLE)) {
      const yOffsetDict = getOffsetFromWindowName(yOffsetRegex);
      const xOffsetDict = getOffsetFromWindowName(xOffsetRegex);

      y = yOffsetDict && yOffsetDict.yOffset;
      x = xOffsetDict && xOffsetDict.xOffset;
    } else {
      y = window.localStorage.getItem('yOffset');
      x = window.localStorage.getItem('xOffset');
    }

    // Scroll vertically to y offset
    window.scrollTo(0, y);

    // Adjust horizontal positioning of tab nav
    // eslint-disable-next-line no-unused-expressions
    tabNav && window.setTimeout(() => $(tabNav).scrollLeft(x), 1);

    // Remove the expired objects in local storage
    // eslint-disable-next-line no-unused-expressions
    this.STORAGE_AVAILABLE && (
      window.localStorage.removeItem('yOffset'),
      window.localStorage.removeItem('xOffset'));

    window.name = '';
  },

  setAnchor (e) {
    if (!e.currentTarget) { return; }

    let topOffset = window.pageYOffset || document.documentElement.scrollTop;
    const pageIsAnchorable = document.querySelector('#tab-nav');
    let yOffsetDictAsString = '';

    const link = e.currentTarget;
    const selector = link.getAttribute('data-anchor-point');

    // If the anchor element exists, get the y offset
    if (selector && pageIsAnchorable) {
      topOffset = this.getYOffset(selector);
    }

    // If the link is a tab-nav item, calculate the x offset
    if (link.classList.contains('tab-nav__item')) {
      const wrapper = document.querySelector('.tab-nav');
      let left = wrapper.scrollLeft;
      let xOffsetDictAsString = '';

      // Detect if item clicked is offscreen
      const bodyRect = document.body.getBoundingClientRect();
      const elRect = link.getBoundingClientRect();
      const rightOffset = bodyRect.right - elRect.right;
      const leftOffset = elRect.left - bodyRect.left;

      if (leftOffset < 0) {
        left -= Math.abs(leftOffset);
      }

      if (rightOffset < 0) {
        left += Math.abs(rightOffset);
      }

      xOffsetDictAsString = `{"xOffset":${left}}`;

      if (this.STORAGE_AVAILABLE) {
        window.localStorage.setItem('xOffset', left);
      } else {
        window.name += xOffsetDictAsString;
      }
    }

    yOffsetDictAsString = `{"yOffset":${topOffset}}`;

    if (this.STORAGE_AVAILABLE) {
      window.localStorage.setItem('yOffset', topOffset);
    } else {
      window.name += yOffsetDictAsString;
    }
  },

  events: () => {
    const e = {};
    const pageIsAnchorable = (
      getParents(document.querySelector('.js-anchor'), '#tab-nav'));
    // eslint-disable-next-line no-unused-expressions
    pageIsAnchorable && (e['click .js-anchor'] = 'setAnchor');

    return e;
  }
});


// =============================================================================
// Main
// =============================================================================

export default AnchorWindowView;

/**
 * Auto initialize
 */
(() =>
 new AnchorWindowView({
   el: document.body
 }))();

/**
 * Controls for a Django formset. Requires Django to render a form template.
 */
import SlugView from './SlugView';

const FormsetView = Backbone.View.extend({
  template () {
    return this.$el.find(`#${this.prefix}-template`).clone(true);
  },

  updateElementIndex (el, ndx) {
    // Taken from django/contrib/admin/static/js/inlines.js
    var idRegex = new RegExp('__prefix__');
    var replacement = ndx;
    if ($(el).prop('for')) {
      $(el).prop('for', $(el).prop('for').replace(idRegex, replacement));
    }
    if (el.id) {
      el.id = el.id.replace(idRegex, replacement);
    }
    if (el.name) {
      el.name = el.name.replace(idRegex, replacement);
    }
  },

  updateTrixToolbar (row, el, ndx) {
    const originalId = el.id;
    el.id = `trix-toolbar-${ndx}`;
    row.find(`trix-editor[toolbar="${originalId}"]`).each((idx, editor) => {
      editor.setAttribute('trix-id', ndx);
      editor.setAttribute('toolbar', el.id);
    });
  },

  updateSlugSearch (el) {
    // initialize new slug search view
    const $this = $(el);

    new SlugView({
      el: $this,
      url: $this.data('url'),
    });
  },

  initialize (options) {
    this.options = _.extend({}, {
      onRemove: function() {},
      onAdd: function() {},
      onInit: function() {},
    }, options);

    this.prefixSnapshot = [];

    const currentOptions = this.options;
    const initialForms = (() => {
      // gather all forms in set, add as objects
      const $forms = this.$el.find('.js-form').not('[id*="__prefix__"]');
      return $forms.map(function(index, el) {
        const $el = $(el);
        const formPrefix = el.id;
        const deleted = $el.find(`input#id_${formPrefix}-DELETE`).is(':checked');

        if (deleted) {
          $el.hide();
        }

        return {
          formPrefix: formPrefix,
          DELETE: deleted
        };
      }).get();
    })();

    this.prefix = this.$el.attr('id');
    this.collection.set(initialForms);

    initialForms.forEach((element) => {
      currentOptions.onInit(element.formPrefix);
    });
    this.toggleRemoveDisplay();
    this.toggleAddDisplay();
    // TODO assert our collection has the same number as INITIAL_FORMS
    this.collection.on('add', this.render, this);
    this.collection.on('add', this.update, this);
  },

  render () {
    const $newEl = $(this.template());
    const templateRegex = new RegExp('-template');
    $newEl[0].id = $newEl[0].id.replace(templateRegex, `-template-${this.collection.length - 1}`);
    $newEl.find('*').each((idx, el) => {
      // set indexes one by one to trigger updates
      this.updateElementIndex(el, this.collection.length - 1);
    });
    const numTrixToolbars = document.getElementsByTagName('trix-toolbar').length;
    $newEl.find('trix-toolbar').each((idx, el) => {
      // update trix toolbars to connect to trix editors properly
      this.updateTrixToolbar($newEl, el, numTrixToolbars + idx + 1);
    });

    let parent = this.$el.find('.js-form').last();
    if (this.collection.length === 1) {
      parent = parent.parent();
    }

    $newEl.hide().insertAfter(parent);
    $newEl.slideDown();

    $newEl.find('.js-slug-search').each((idx, el) => {
      this.updateSlugSearch(el);
    });

    return this;
  },

  add () {
    const formPrefix = `${this.prefix}-${this.collection.length}`;
    this.collection.add({
      formPrefix: formPrefix,
      DELETE: false
    });

    var focusElement = this.$el.find(`#${formPrefix} .js-focus-first`);
    if (focusElement.length > 0) focusElement.first().focus();
    else this.$el.find(`#${formPrefix} input`).first().focus();
    this.options.onAdd(formPrefix);
  },

  remove (formPrefix) {
    const model = this.collection.findWhere({ formPrefix: formPrefix });
    model.set('DELETE', true);
    // dont remove model so we always create new form prefixes (by length)
    // dont remove the form el, Django still processes it
    this.update(); // collection remove event never fires, manually update
    // Information about deleting this formset
    this.$el.append(`<input name="${formPrefix}-DELETE" value="True" id="${formPrefix}-DELETE" type="hidden"></input>`);
    this.options.onRemove(formPrefix);
  },

  reAddAll () {
    if (!this.prefixSnapshot) return;

    for (var i = 0; i < this.collection.length; i++) {
      const model = this.collection.models[i];
      if (this.prefixSnapshot.includes(model.attributes.formPrefix)) {
        model.set('DELETE', false);
        this.update();
        this.$el.find(`input#${model.attributes.formPrefix}-DELETE`).remove();
        this.options.onAdd(model.attributes.formPrefix);
      }
    }
  },

  removeAll () {
    this.prefixSnapshot = [];

    for (var i = 0; i < this.collection.length; i++) {
      const model = this.collection.models[i];
      if (!model.get('DELETE')) this.prefixSnapshot.push(model.attributes.formPrefix)
      this.remove(model.attributes.formPrefix);
    }
  },

  update () {
    this.$el.find(`[name="${this.prefix}-TOTAL_FORMS"]`).val(this.collection.length);
    this.toggleRemoveDisplay();
    this.toggleAddDisplay();
  },

  canAdd () {
    const limit = this.$el.find(`[name="${this.prefix}-MAX_NUM_FORMS"]`).val();
    return this.collection.where({
      DELETE: false
    }).length < limit;
  },

  toggleAddDisplay () {
    const $addButtons = this.$el.find('.js-add');
    if (this.canAdd()) {
      $addButtons.show();
    } else {
      $addButtons.hide();
    }
  },

  canRemove () {
    return this.collection.where({
      DELETE: false
    }).length > this.$el.find(`[name="${this.prefix}-MIN_NUM_FORMS"]`).val();
  },

  toggleRemoveDisplay () {
    const $removeButtons = this.$el.find('.js-remove, .js-remove-element');
    if (this.canRemove()) {
      $removeButtons.show();
    } else {
      $removeButtons.hide();
    }
  },

  eventRemove(e) {
    const $el = $(e.currentTarget);
    const $form = $el.closest('.js-form');
    const formPrefix = $form.attr('id');
    $form.slideUp();
    this.remove(formPrefix);
  },

  events: {
    'click .js-add': function(e) {
      e.preventDefault();
      e.stopPropagation();
      this.add();
    },
    'keydown .js-add': function(e) {
      if (e.keyCode == 13 || e.keyCode == 32) {
        e.preventDefault();
        e.stopPropagation();
        this.add();
      }
    },
    'click .js-remove': function(e) {
      e.preventDefault();
      e.stopPropagation();
      this.eventRemove(e);
    },
    'keydown .js-remove': function(e) {
      if (e.keyCode == 13 || e.keyCode == 32) {
        e.preventDefault();
        this.eventRemove(e);
      }
    },
    'click .js-remove-element': function(e) {
      e.preventDefault();
      e.stopPropagation();
      this.eventRemove(e);
    },
    'keydown .js-remove-element': function(e) {
      if (e.keyCode == 13 || e.keyCode == 32) {
        e.preventDefault();
        this.eventRemove(e);
      }
    },
  }
});

export default FormsetView;

/**
 * Backbone View that starts with the functionality we need for basic form
 * validation. Depends on backbone.validation (https://github.com/thedersen/backbone.validation).
 *
 * @class
 */
import '../utils/validation';
import { validationFieldClass } from '../utils';

const FormValidationView = Backbone.View.extend({
  /** Cached jQuery objects for later remove() */
  $fieldEls: null,

  /** Binds validation and sets up fields with validation classes */
  initialize (options) {
    this.options = _.extend({}, {
      excludedAttrs: [],
    }, options);
    Backbone.Validation.bind(this);
    this._checkValid = _.debounce(this.checkValid, 200);

    // FIXME add this class in the backend widget templates instead
    this.$fieldEls = this.$(':input').not('[type="checkbox"],[type="radio"],[type="hidden"]');
    this.$fieldEls.addClass(validationFieldClass);

    // Handle API Errors and add aria-invalid attribute for combined fields
    const errors = this.$('.errorlist li');
    errors.each((index) => {
      const error = errors.eq(index);
      const $group = $(error).parents('.validation--group');
      $group.find('input, select, textarea').attr("aria-invalid", true);
    })
  },

  /** Serializes child :inputs and sets the data to our model */
  setData () {
    _.each(this.$(':input').serializeArray(), _.bind(function(item) {
      this.model.set(item.name, item.value);
    }, this));
  },

  /**
   * Determines if a field identified by `attrs` is valid.
   *
   * @param {String,Array} attrs - name attribute(s) of field(s)
   */
  checkValid (attrs) {
    if (this.options.excludedAttrs.every((excluded) => {
      if (attrs.every) {
        return attrs.every(attr => !attr.includes(excluded));
      }

      return !attrs.includes(excluded);
    })) {
      return this.validateAttribute(attrs);
    }

    return this.model.isValid([]);
  },

  validateAttribute (attrs) {
    this.setData();
    return this.model.isValid(attrs);
  },

  /**
   * Avoids validating inputs without names which would trigger
   * validation on all form fields
   */
  doCheckValid (e) {
    const name = e.currentTarget.name;
    const isDisabled = e.currentTarget.disabled;
    if (!name || isDisabled) {
      return;
    }
    this.checkValid(name);
  },

  lazyCheckValid (attrs) {
    this._checkValid(attrs);
  },

  preValidate (attrNames) {
    this.setData();

    const attrs = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const attrIndex in attrNames) {
      const attrName = attrNames[attrIndex];
      if (_.has(this.model.attributes, attrName)) {
        attrs[attrName] = this.model.attributes[attrName];
      } else {
        attrs[attrName] = undefined;
      }
    }

    return this.model.preValidate(attrs);
  },

  /**
   * Avoids validating inputs without names which would trigger
   * validation on all form fields
   */
  doLazyCheckValid (e) {
    const name = e.currentTarget.name;
    const isDisabled = e.currentTarget.disabled;
    if (!name || isDisabled) {
      return;
    }
    this.lazyCheckValid(name);
  },

  /**
   * Determines if a field justification should be displayed. Ensures parent
   * field is valid before displaying justification. Selector is optional and
   * by default is `#<attr>-justification`.
   *
   * @param {Number} val - value of field
   * @param {String} attr - name attribute of field
   * @param {Boolean} justificationRequired - is justification required
   * @param {String} selector - selector of justification to display
   */
  checkJustification (val, attr, justificationRequired, selector) {
    this.setData(); // manually do this instead of checkValid due to debouncing race condition
    const valid = this.model.isValid(attr);

    this.showJustification(val, attr, valid && justificationRequired, selector)
  },

    /**
   * Determines if a field justification should be displayed. DOes not ensure parent
   * field is valid before displaying justification. Selector is optional and
   * by default is `#<attr>-justification`.
   *
   * @param {Number} val - value of field
   * @param {String} attr - name attribute of field
   * @param {Boolean} displayJustification - should display justification
   * @param {String} selector - selector of justification to display
   */
  showJustification (val, attr, displayJustification, selector) {
      selector = selector || `#${attr}-justification`;
      this.setData(); // manually do this instead of checkValid due to debouncing race condition
      this.display(displayJustification, selector);
    },

  /**
   * Displays or hides an element identified by `selector` based on the
   * value of `display`. Display change is implemented by slide up/down.
   *
   * @param {Boolean} display - display the element
   * @param {String} selector - selector of justification to display
   */
  display (display, selector) {
    const $el = this.$el.find(selector);

    if (display) {
      $el.slideDown();
    } else {
      $el.slideUp();
    }
  },

  enableSubmit () {
    $('button[type="submit"]').prop('disabled', false).change();
  },

  /** Unbinds validation and removes validation classes from fields */
  remove (...args) {
    Backbone.Validation.unbind(this);
    this.$fieldEls.removeClass(validationFieldClass);
    return Backbone.View.prototype.remove.apply(this, args);
  },

  /**
   * Input and textarea changes are validated ondemand, others are
   * lazily validated to avoid repetitive validation calls for frequent
   * updates
   */
  events: {
    'change input': 'doCheckValid',
    'change textarea': 'doCheckValid',
    // The 'not' selector is used below in order for the correct error
    // message to display when an invalid file is uploaded for resume.
    'input input:not([type="file"])': 'doLazyCheckValid',
    'input textarea': 'doLazyCheckValid',
    'change [type="checkbox"]': 'doLazyCheckValid',
    'change [type="radio"]': 'doLazyCheckValid',
    'change select': 'doLazyCheckValid'
  }

});

export default FormValidationView;

// =============================================================================
// ReadMore View Component
//
// @param {HTMLElement} el
//        Search root for `readMoreSelector` and `contentSelector`
// @param {number} limit
//        Max character limit.
// @param {string} readMoreSelector
//        Selector for read more element.
// @param {string} contentSelector
//        Selector for element containing the content.
// =============================================================================

const closest = (el, selector) => {
  let matchesFn = null;

  ['matches',
   'webkitMatchesSelector',
   'mozMatchesSelector',
   'msMatchesSelector',
   'oMatchesSelector'].some((fn) => {
     if (typeof document.body[fn] === 'function') {
       matchesFn = fn;
       return true;
     }
     return false;
   });

  let parent = null;
  let prevSibling = null;

  while (el) {
    if (el.previousElementSibling) {
      prevSibling = el.previousElementSibling;
      if (prevSibling[matchesFn](selector)) {
        return prevSibling;
      }
      el = prevSibling;
    }
    parent = el.parentElement;
    if (parent && parent[matchesFn](selector)) {
      return parent;
    }
    el = parent;
  }

  return null;
};

const ReadMoreView = Backbone.View.extend({
  initialize (options) {
    this.limit = options.limit;
    this.readMoreLinks = this.el.querySelectorAll(options.readMoreSelector);
    this.contentElements = this.el.querySelectorAll(options.contentSelector);
    this.selector = options.contentSelector;
    this.render();
  },
  render () {
    if (this.contentElements.length) {
      let fullContent = null;
      let truncContent = null;

      [...this.contentElements].forEach(function (contentEl) {
        fullContent = contentEl.innerHTML;

        if (fullContent.length > this.limit) {
          truncContent = fullContent.substring(0, this.limit);
          truncContent = `${truncContent.substr(0, Math.min(truncContent.length, truncContent.lastIndexOf(' ')))} ... `;
          contentEl.innerHTML = truncContent;
        }
      }, this);

      [...this.readMoreLinks].forEach(function (readMoreLink) {
        readMoreLink.addEventListener('click', (e) => {
          e.preventDefault();

          const closestValidExpandableEl = closest(e.target, this.selector);

          if (closestValidExpandableEl) {
            if (!closestValidExpandableEl.dataset.expanded) {
              closestValidExpandableEl.innerHTML = fullContent;
              readMoreLink.innerHTML = 'Read Less';
              closestValidExpandableEl.dataset.expanded = 'expanded';
            } else if (closestValidExpandableEl.dataset.expanded) {
              closestValidExpandableEl.innerHTML = truncContent;
              readMoreLink.innerHTML = 'Read More';
              closestValidExpandableEl.dataset.expanded = '';
            }
          }
        });
      }, this);
    }
  },
});

export default ReadMoreView;
